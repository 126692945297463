import React from 'react';
import PropTypes from 'prop-types';
import { Interpreter } from 'xstate';
import { useService } from '@xstate/react';

import { Box } from '@material-ui/core';

import { FORM_ACTIONS } from 'machines/utils/form';
import Form from '../base-form';
import { TEXT, useStyles } from './constants';

export default function CreateAccountTemplate({
  cities,
  transportTypes,
  CreateAccountMachine
}) {
  const classes = useStyles();

  const [current, send] = useService(CreateAccountMachine);
  const { fields } = current.context;
  const invalid = current.matches('editing.invalid');
  const submiting = current.matches('submiting');
  const disableSubmit = invalid || submiting;

  const handleChange = data => send(FORM_ACTIONS.change, data);
  const handleSubmit = event => {
    event.preventDefault();
    send(FORM_ACTIONS.submit);
  };

  const onFullTerms = () => {
    window.location.href = TEXT.FULL_TERMS;
  };

  const onPrivacyLink = () => {
    window.location.href = TEXT.PRIVACY_LINK;
  };

  const termsLabel = (
    <>
      {TEXT.TERMS}{' '}
      <Box component="a" className={classes.link} onClick={onFullTerms}>
        {TEXT.LINK_TERMS}
      </Box>{' '}
      {TEXT.AND_PRIVACY_LINK}{' '}
      <Box component="a" className={classes.link} onClick={onPrivacyLink}>
        {TEXT.LINK_PRIVACY}
      </Box>
      .
    </>
  );

  const inputList = [
    { name: 'name', placeholder: TEXT.FULL_NAME },
    {
      name: 'email',
      placeholder: TEXT.EMAIL,
      type: 'email'
    },
    {
      name: 'mobileNumber',
      placeholder: TEXT.PHONE,
      type: 'tel'
    },
    {
      name: 'cpf',
      placeholder: TEXT.CPF,
      inputProps: { maxLength: 14 },
      type: 'tel'
    },
    {
      name: 'citySlug',
      placeholder: TEXT.CITY,
      options: cities.map(city => {
        return { key: city.id, value: city.slug, label: city.name };
      }),
      isSelect: true
    },
    {
      name: 'transportType',
      placeholder: TEXT.TRANSPORT_TYPE,
      options: transportTypes.map(type => {
        return { key: type.id, value: type.id, label: type.name };
      }),
      isSelect: true
    },
    { name: 'terms', isCheckbox: true, label: termsLabel }
  ];

  return (
    <Form onChange={handleChange} onSubmit={handleSubmit} fields={fields}>
      <Form.Header title={TEXT.TITLE} />

      {inputList.map(field => (
        <Form.Input key={field.name} field={field} />
      ))}

      <Box pb={2.5}>{TEXT.MARKETING}</Box>

      <Form.Submit text={TEXT.CREATE_ACCOUNT} disabled={disableSubmit} />
    </Form>
  );
}

CreateAccountTemplate.propTypes = {
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ).isRequired,
  transportTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  CreateAccountMachine: PropTypes.instanceOf(Interpreter).isRequired
};
