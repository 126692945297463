import React from 'react';
import PropTypes from 'prop-types';
import Form from 'view/templates/base-form';
import { TEXTS } from './constants';

const DriverModalFormTemplate = ({
  onChange,
  onSubmit,
  context,
  disableSubmit
}) => {
  const { fields, transportTypes } = context;

  const inputField = {
    name: 'transportType',
    placeholder: TEXTS.FORM.TRANSPORTTYPE,
    options: transportTypes.map(transportType => ({
      key: transportType.id,
      value: transportType.id,
      label: transportType.name
    })),
    isSelect: true
  };

  return (
    <Form onChange={onChange} onSubmit={onSubmit} fields={fields}>
      <Form.Header title={TEXTS.TITLE} subtitle={TEXTS.SUBTITLE} />

      <Form.Input key={inputField.name} field={inputField} />

      <Form.Submit text="Atualizar" disabled={disableSubmit} />
    </Form>
  );
};

DriverModalFormTemplate.propTypes = {
  context: PropTypes.shape({
    fields: PropTypes.objectOf(PropTypes.shape({})),
    transportTypes: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  disableSubmit: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
};

DriverModalFormTemplate.defaultProps = {
  disableSubmit: false,
  onChange: () => {
    // this is correctly
  },
  onSubmit: () => {
    // this is correctly
  }
};

export default DriverModalFormTemplate;
