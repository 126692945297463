import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '../constants';

const Picture = ({ picture }) => {
  const styles = useStyles();
  return <img alt="Cnh" src={picture} className={styles.image} />;
};

Picture.propTypes = {
  picture: PropTypes.string.isRequired
};

export default Picture;
