import { Machine, assign, sendParent } from 'xstate';
import checkEmailVerificationService from 'infra/services/check-email-verification';
import { getDriverDocuments } from 'infra/services/driver-documents';
import getDriver from 'infra/services/get-driver';
import { storeCredentials } from 'infra/storage/credential';
import { isEmbededDriverApp } from 'shared/utils/driver-app';
import { pure } from 'xstate/lib/actions';
import { initialContext } from './orchestrator.machine';

export const actions = {
  setDriver: assign((_, { data }) => ({
    userId: data.userId,
    transportType: data.transportType,
    profilePicture: data.profilePicture,
    cnh: data.cnh,
    firstName: data.firstName
  })),
  setDriverParent: sendParent((_, { data }) => ({
    type: 'SETUSER',
    data: {
      userId: data.userId,
      transportType: data.transportType,
      photoUrl: data.photoUrl,
      firstName: data.firstName,
      email: data.email,
      emailIsVerified: data.emailIsVerified
    }
  })),
  clearDriver: sendParent(() => ({
    type: 'SETUSER',
    data: initialContext.driver
  })),
  sendAuthorized: sendParent(() => ({
    type: 'AUTHORIZED'
  })),
  sendUnauthorized: sendParent(() => ({
    type: 'UNAUTHORIZED'
  })),
  setDocuments: sendParent((_, { data }) => ({
    type: 'SET_DOCUMENTS',
    data: { documents: data.documentSummary }
  })),
  setErrors: assign({
    errors: (_, { data }) => data.errors
  }),
  checkDriverAuthorization: pure((_, { data }) => {
    if (data?.code === 412) {
      return [sendParent({ type: 'AUTHORIZED' })];
    }
    return [
      sendParent({ type: 'SETUSER', data: initialContext.driver }),
      sendParent({ type: 'UNAUTHORIZED' })
    ];
  })
};

export const services = {
  getDriver,
  getDocuments: ctx => getDriverDocuments(ctx.userId),
  checkEmailVerificationService,
  checkAuthByApiKey: ({ searchParams }) => {
    if (searchParams) {
      const authByApiKey = searchParams.get('auth');

      if (isEmbededDriverApp() && authByApiKey) {
        const [email, apiKey] = authByApiKey.replace('ApiKey ', '').split(':');
        storeCredentials({
          email,
          apiKey,
          position: {
            latitude: searchParams.get('latitude'),
            longitude: searchParams.get('longitude')
          }
        });
      }
    }

    return Promise.resolve();
  }
};

const initializationMachine = Machine(
  {
    id: 'initializationMachine',
    initial: 'checkAuthByApiKey',
    context: {
      userId: null,
      transportType: null,
      firstName: null,
      errors: []
    },
    states: {
      checkAuthByApiKey: {
        invoke: {
          src: 'checkAuthByApiKey',
          onDone: {
            target: 'getDriver'
          }
        }
      },
      getDriver: {
        invoke: {
          src: 'getDriver',
          onDone: {
            target: 'checkEmailVerificationService',
            actions: ['setDriver', 'setDriverParent']
          },
          onError: {
            target: 'error',
            actions: ['setErrors', 'clearDriver', 'sendUnauthorized']
          }
        }
      },
      checkEmailVerificationService: {
        invoke: {
          src: 'checkEmailVerificationService',
          onDone: {
            target: 'getDocuments'
          },
          onError: {
            target: 'error',
            actions: ['sendUnauthorized']
          }
        }
      },
      getDocuments: {
        invoke: {
          src: 'getDocuments',
          onDone: {
            actions: ['setDocuments', 'sendAuthorized']
          },
          onError: {
            target: 'error',
            actions: ['setErrors', 'checkDriverAuthorization']
          }
        }
      },
      error: {
        on: {
          RETRY: 'loading'
        }
      }
    }
  },
  {
    services,
    actions
  }
);

export default initializationMachine;
