import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from '../utils';
import { DOCUMENT_CARD_ERROR_TITLE } from '../constants';
import LoggiIcon from '../images/loggi.png';

const CardModal = ({ testId, onClick, disabled, error, text, withIcon }) => {
  const style = useStyles();
  const bulletStyle = 'bulletError';
  const cardStyle = 'documentCardError';
  return (
    <Box
      data-testid={testId}
      className={`${style[cardStyle]} ${!disabled && style.documentCardLink}`}
      minHeight="100px"
      mt={1.25}
      py={2.5}
      borderRadius={8}
      display="flex"
      onClick={onClick}
    >
      {withIcon ? (
        <Box ml={2.5} mr={1}>
          <img alt="icon" src={LoggiIcon} />
        </Box>
      ) : (
        <Box mt={3.5} ml={2.5} mr={2}>
          <Box
            className={style[bulletStyle]}
            width={10}
            height={10}
            borderRadius={100}
          />
        </Box>
      )}

      <Box display="flex" flexDirection="column">
        <Typography component="span" variant="body1">
          {error ? (
            <>
              <Box
                className={style.documentCardErrorMessage}
                fontWeight="fontWeightBold"
              >
                {DOCUMENT_CARD_ERROR_TITLE}
              </Box>
              <Box key={error}>• {error}</Box>
            </>
          ) : (
            text
          )}
          <Box
            sx={{
              justifyContent: 'flex-end'
            }}
            fontWeight="fontWeightBold"
          >
            Mudar meio de transporte
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

CardModal.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  error: PropTypes.string,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  withIcon: PropTypes.bool
};

CardModal.defaultProps = {
  error: null,
  testId: null,
  disabled: false,
  withIcon: false
};

export default CardModal;
