import { Machine, assign } from 'xstate';
import uploadFile from 'infra/services/upload-file';
import { sendDriverDocument } from 'infra/services/driver-documents';
import getDriverProfilePicture from 'infra/services/get-driver-picture';
import { DOCUMENT_STATUS, DOCUMENT_TYPE } from 'shared/constants/documents';

import {
  emptyField,
  changeField,
  mergeValidationErrors,
  sendErrorNotification,
  sendSuccessNotification,
  FORM_STATE,
  FORM_ACTIONS
} from './utils/form';

export const initialContext = {
  fields: {
    file: emptyField()
  },
  errors: []
};

const FORM_NOTIFICATIONS = {
  success: 'Beleza! A foto foi enviada com sucesso.',
  error: 'Opa! A foto não foi enviada, tente de novo.'
};

export const guards = {
  documentInvalid: ctx => {
    return ctx.documents.some(
      ({ documentType, documentStatus }) =>
        documentType === DOCUMENT_TYPE.PROFILE_PICTURE &&
        documentStatus === DOCUMENT_STATUS.INVALID
    );
  },
  documentValid: ctx => {
    return ctx.documents.some(
      ({ documentType, documentStatus }) =>
        documentType === DOCUMENT_TYPE.PROFILE_PICTURE &&
        documentStatus === DOCUMENT_STATUS.VALID
    );
  },
  formValid: ctx => ctx.fields.file.value,
  formInvalid: ctx => !ctx.fields.file.value
};

export const actions = {
  changeField,
  mergeValidationErrors,
  sendErrorNotification: sendErrorNotification(FORM_NOTIFICATIONS.error, {
    getErrorFromEvent: true
  }),
  sendSuccessNotification: sendSuccessNotification(FORM_NOTIFICATIONS.success),
  setDriverDocuments: assign({
    driver: (ctx, { data }) => ({
      ...ctx.driver,
      profilePicture: data.profilePicture
    })
  })
};

const services = {
  getDriverProfilePicture,
  updateDriverProfilePicture: async ({ driver, fields }) => {
    const { file } = await uploadFile(driver.userId, fields.file.value);
    return sendDriverDocument(driver.userId, 'profile-picture', {
      picture: {
        filepath: file
      }
    });
  }
};

const ProfilePictureMachine = Machine(
  {
    id: 'profilePicture',
    initial: FORM_STATE.loading,
    context: initialContext,
    on: {
      [FORM_ACTIONS.edit]: {
        target: FORM_STATE.editing
      }
    },
    states: {
      [FORM_STATE.loading]: {
        invoke: {
          id: 'getDriverProfilePicture',
          src: 'getDriverProfilePicture',
          onDone: {
            target: FORM_STATE.idle,
            actions: 'setDriverDocuments'
          },
          onError: {
            target: FORM_STATE.error,
            actions: [
              assign({
                errors: (_, { data }) => {
                  return data.errors;
                }
              })
            ]
          }
        }
      },
      [FORM_STATE.idle]: {
        on: {
          '': [
            { target: FORM_STATE.documentInvalid, cond: 'documentInvalid' },
            { target: FORM_STATE.documentValid, cond: 'documentValid' }
          ]
        }
      },
      [FORM_STATE.editing]: {
        initial: FORM_STATE.invalid,
        states: {
          [FORM_STATE.invalid]: {
            on: {
              '': [{ target: FORM_STATE.valid, cond: 'formValid' }]
            }
          },
          [FORM_STATE.valid]: {
            on: {
              '': [{ target: FORM_STATE.invalid, cond: 'formInvalid' }]
            }
          }
        },
        on: {
          [FORM_ACTIONS.submit]: {
            target: FORM_STATE.submitting,
            cond: 'formValid'
          },
          [FORM_ACTIONS.change]: {
            actions: 'changeField'
          }
        }
      },
      [FORM_STATE.submitting]: {
        invoke: {
          id: 'updateDriverProfilePicture',
          src: 'updateDriverProfilePicture',
          onDone: {
            target: FORM_STATE.success,
            actions: 'sendSuccessNotification'
          },
          onError: {
            target: FORM_STATE.editing,
            actions: ['mergeValidationErrors', 'sendErrorNotification']
          }
        }
      },
      [FORM_STATE.success]: {
        type: 'final'
      },
      [FORM_STATE.error]: {
        on: {
          [FORM_ACTIONS.retry]: FORM_STATE.loading
        }
      },
      [FORM_STATE.documentValid]: {
        type: 'final'
      },
      [FORM_STATE.documentInvalid]: {}
    }
  },
  {
    guards,
    actions,
    services
  }
);

export default ProfilePictureMachine;
