import graphqlRequest from 'infra/graphql-request/';

export const validateSignupFieldsQuery = `
  query validateSignupFields($name: String!, $cpf: String!, $mobileNumber: String!, $email: String!) {
    validateSignupFields(name: $name, mobileNumber: $mobileNumber, cpf: $cpf, email: $email) {
      field
      message
    }
  }
`;

export default function validateSignupFields(variables) {
  return graphqlRequest({
    query: validateSignupFieldsQuery,
    variables
  }).then(response => {
    const data = response.data.validateSignupFields;

    if (!data.length) {
      return data;
    }

    const errors = {
      errors: data.map(item => {
        return {
          message: item.message,
          field: item.field === 'mobile_number' ? 'mobileNumber' : item.field
        };
      })
    };

    return Promise.reject(errors);
  });
}
