import { Machine, assign } from 'xstate';
import * as yup from 'yup';
import getLicensePlateData from 'infra/services/license-plate-data';
import { sendDriverDocument } from 'infra/services/driver-documents';

import {
  emptyField,
  formValid,
  formInvalid,
  changeField,
  validateFields,
  initialContextFromFieldsValues,
  mergeValidationErrors,
  sendErrorNotification,
  sendSuccessNotification,
  FORM_STATE,
  FORM_ACTIONS
} from './utils/form';
import formatLicensePlate from './utils/form/formatters/license-plate';
import isLicensePlateValid from './utils/form/validators/license-plate';

export const initialContext = {
  transportTypes: [],
  fields: {
    licensePlate: emptyField()
  },
  errors: []
};

export const FORM_ERRORS = {
  licensePlate: 'Eita, digite uma placa válida para criar seu cadastro.'
};

const FORM_NOTIFICATIONS = {
  success: 'Maravilha! A placa foi enviada com sucesso.',
  error: 'Opa! A placa não foi enviada, tente de novo.'
};

const licensePlateSchema = yup.object().shape({
  licensePlate: yup
    .string()
    .test('licensePlate', FORM_ERRORS.licensePlate, isLicensePlateValid)
    .required(FORM_ERRORS.licensePlate)
    .transform(formatLicensePlate)
});

export const guards = {
  formValid,
  formInvalid
};

export const actions = {
  changeField,
  mergeValidationErrors,
  validateFields: validateFields(licensePlateSchema),
  sendErrorNotification: sendErrorNotification(FORM_NOTIFICATIONS.error),
  sendSuccessNotification: sendSuccessNotification(FORM_NOTIFICATIONS.success)
};

const services = {
  getLicensePlateData,
  updateLicensePlate: ({ driver, fields }) =>
    sendDriverDocument(driver.userId, 'vehicle', {
      vehicle: {
        license_plate: fields.licensePlate.value,
        transportType: driver.transportType
      }
    })
};

const licensePlateMachine = Machine(
  {
    id: 'licensePlate',
    initial: FORM_STATE.loading,
    context: initialContextFromFieldsValues(initialContext, licensePlateSchema),
    states: {
      [FORM_STATE.loading]: {
        invoke: {
          id: 'getLicensePlateData',
          src: 'getLicensePlateData',
          onDone: {
            actions: [
              assign((_, { data }) => {
                if (data) {
                  return {
                    fields: {
                      licensePlate: {
                        ...emptyField(),
                        value: data
                      }
                    }
                  };
                }

                return {};
              })
            ],
            target: FORM_STATE.editing
          },
          onError: {
            actions: [
              assign(() => {
                const { fields } = initialContext;
                return {
                  fields
                };
              })
            ],
            target: FORM_STATE.failure
          }
        }
      },
      [FORM_STATE.editing]: {
        initial: FORM_STATE.invalid,
        states: {
          [FORM_STATE.invalid]: {
            on: {
              '': [{ target: FORM_STATE.valid, cond: 'formValid' }]
            }
          },
          [FORM_STATE.valid]: {
            on: {
              '': [{ target: FORM_STATE.invalid, cond: 'formInvalid' }]
            }
          }
        },
        on: {
          [FORM_ACTIONS.submit]: {
            target: FORM_STATE.submitting,
            cond: 'formValid'
          },
          [FORM_ACTIONS.change]: {
            actions: ['changeField', 'validateFields']
          }
        }
      },
      [FORM_STATE.submitting]: {
        invoke: {
          id: 'updateLicensePlate',
          src: 'updateLicensePlate',
          onDone: {
            target: FORM_STATE.success,
            actions: 'sendSuccessNotification'
          },
          onError: {
            target: FORM_STATE.editing,
            actions: ['mergeValidationErrors', 'sendErrorNotification']
          }
        }
      },
      [FORM_STATE.success]: {
        type: 'final'
      },
      [FORM_STATE.failure]: {
        on: {
          [FORM_ACTIONS.retry]: FORM_STATE.loading
        }
      }
    }
  },
  {
    guards,
    actions,
    services
  }
);

export default licensePlateMachine;
