import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, Typography } from '@material-ui/core';

import { TEXT, useStyles } from './constants';

export default function ErrorCriticalTemplate({ firstName }) {
  const classes = useStyles();

  return (
    <Box className={classes.containerPage}>
      <Grid container justify="space-between">
        <Box pt={2.5}>
          <Typography variant="h2">
            <Box
              fontSize={28}
              fontWeight="fontWeightBold"
              data-testid="txt-name"
            >
              {firstName},
            </Box>
            <Box fontSize={24} data-testid="txt-title">
              {TEXT.TITLE}
            </Box>
          </Typography>

          <Box>
            <Typography variant="h6">
              <Box
                fontSize={16}
                data-testid="txt-subtitle"
                dangerouslySetInnerHTML={{ __html: TEXT.SUBTITLE }}
              />
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}

ErrorCriticalTemplate.propTypes = {
  firstName: PropTypes.string.isRequired
};
