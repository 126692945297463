import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { getBulletClass, getCardClass, useStyles } from '../utils';
import { DOCUMENT_CARD_ERROR_TITLE } from '../constants';
import { ReactComponent as Arrow } from '../images/arrow.svg';
import LoggiIcon from '../images/loggi.png';

const Card = ({
  status,
  testId,
  onClick,
  disabled,
  error,
  title,
  text,
  withIcon
}) => {
  const style = useStyles();
  const bulletStyle = getBulletClass(status);
  const cardStyle = getCardClass(status);

  return (
    <Box
      data-testid={testId}
      className={`${style[cardStyle]} ${!disabled && style.documentCardLink}`}
      minHeight="100px"
      mt={1.25}
      py={2.5}
      borderRadius={8}
      display="flex"
      onClick={!disabled ? onClick : null}
    >
      {withIcon ? (
        <Box ml={2.5} mr={1}>
          <img alt="icon" src={LoggiIcon} />
        </Box>
      ) : (
        <Box mt={3.5} ml={2.5} mr={2}>
          <Box
            className={style[bulletStyle]}
            width={10}
            height={10}
            borderRadius={100}
          />
        </Box>
      )}

      <Box display="flex" flexDirection="column">
        <Typography component="span" variant="h6">
          <Box fontWeight="fontWeightBold">{title}</Box>
        </Typography>
        <Box mr={2}>
          <Typography component="span" variant="body1">
            {error ? (
              <>
                <Box
                  className={style.documentCardErrorMessage}
                  fontWeight="fontWeightBold"
                >
                  {DOCUMENT_CARD_ERROR_TITLE}
                </Box>
                <Box key={error}>• {error}</Box>
              </>
            ) : (
              text
            )}
          </Typography>
        </Box>
      </Box>

      {!disabled && (
        <Box mt={2.5} mr={2.5} ml="auto">
          <Arrow />
        </Box>
      )}
    </Box>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  error: PropTypes.string,
  status: PropTypes.string,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  withIcon: PropTypes.bool
};

Card.defaultProps = {
  error: null,
  status: null,
  testId: null,
  disabled: false,
  withIcon: false
};

export default Card;
