import React from 'react';
import { Interpreter } from 'xstate';
import PropTypes from 'prop-types';
import { useService } from '@xstate/react';
import CnhFormTemplate from 'view/templates/cnh-form';
import SignupLayoutTemplate from 'view/templates/signup-layout';
import LoadingTemplate from 'view/templates/loading';
import LoadingErrorTemplate from 'view/templates/loading-error';
import PAGES from 'routes/pages';
import { FORM_ACTIONS, FORM_STATE } from 'machines/utils/form';
import { isDocumentEditable } from 'shared/utils/documents';
import { DOCUMENT_TYPE } from 'shared/constants/documents';

const CnhForm = ({ context, history, isDesktop }) => {
  const { services } = context;
  const { CnhMachine } = services;
  const [current, send] = useService(CnhMachine);
  const { documents } = current.context;

  const canEditDocument = isDocumentEditable(documents, DOCUMENT_TYPE.CNH);
  const goBack = () => history.push(PAGES.DOCUMENT_HUB.path);

  if (current.matches(FORM_STATE.success) || !canEditDocument) {
    goBack();
    return <></>;
  }

  const setPicture = data => {
    send(FORM_ACTIONS.change, {
      name: 'file',
      value: data,
      touched: true
    });
  };

  const setToken = data => {
    send(FORM_ACTIONS.change, {
      name: 'token',
      value: data,
      touched: true
    });
  };

  const clearForm = () => {
    setPicture(null);
  };

  const handleSubmit = () => {
    send(FORM_ACTIONS.submit);
  };

  const tokenFlowCallback = token => {
    if (token) {
      setToken(token);
      handleSubmit();
    } else {
      send(FORM_ACTIONS.reload);
    }
  };

  const goToEdit = () => {
    send(FORM_ACTIONS.edit, { tokenFlowCallback });
  };

  const retry = () => send(FORM_ACTIONS.retry);

  const defaultPage = () => (
    <CnhFormTemplate
      goToEdit={goToEdit}
      onClear={clearForm}
      onTakePicture={setPicture}
      onSubmit={handleSubmit}
      context={current.context}
      state={current.toStrings().pop()}
    />
  );

  const page =
    {
      [FORM_STATE.loading]: () => <LoadingTemplate />,
      [FORM_STATE.error]: () => <LoadingErrorTemplate onRetry={retry} />
    }[current.value] || defaultPage;

  return (
    <SignupLayoutTemplate isDesktop={isDesktop} page={page()} onBack={goBack} />
  );
};

CnhForm.propTypes = {
  context: PropTypes.shape({
    services: PropTypes.shape({
      CnhMachine: PropTypes.instanceOf(Interpreter)
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  isDesktop: PropTypes.bool
};

CnhForm.defaultProps = {
  history: {},
  isDesktop: false
};

export default CnhForm;
