import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import FlipCameraIos from '@material-ui/icons/FlipCameraIos';
import useStyles from '../style';

const Submit = ({ onClick, onToggleCam }) => {
  const styles = useStyles();
  return (
    <Box
      className={styles.submitButton}
      display="flex"
      justifyContent="space-evenly"
    >
      <Button
        onClick={onToggleCam}
        variant="outlined"
        color="primary"
        className={styles.submitIcon}
      >
        <FlipCameraIos />
      </Button>

      <Button
        onClick={onClick}
        variant="contained"
        color="primary"
        className={styles.submitIcon}
      >
        <PhotoCameraIcon />
      </Button>
    </Box>
  );
};

Submit.propTypes = {
  onClick: PropTypes.func.isRequired,
  onToggleCam: PropTypes.func.isRequired
};

export default Submit;
