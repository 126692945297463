import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '../constants';

const Picture = ({ picture }) => {
  const styles = useStyles();
  return (
    <img
      alt="Foto de perfil"
      src={picture}
      className={`${styles.image} ${styles.profilePicture}`}
    />
  );
};

Picture.propTypes = {
  picture: PropTypes.string.isRequired
};

export default Picture;
