import wretch from 'wretch';
import graphqlRequest from 'infra/graphql-request/';

const requestGetS3Data = `
  query ($pk: Int) {
    viewer {
      uploadFile(objectId: $pk, uploadType: "driver_docs", extension: "jpeg") {
        action
        fields{
          name
          value
        }
      }
    }
  }
`;

const getS3Data = driverId =>
  graphqlRequest({
    query: requestGetS3Data,
    variables: { pk: driverId }
  }).then(({ data, errors }) => {
    if (data.viewer) {
      return Promise.resolve(data.viewer.uploadFile);
    }
    const errorResponse = { errors };
    return Promise.reject(errorResponse);
  });

const mapFieldsToForm = fields =>
  fields.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]: field.value
    }),
    {}
  );

const uploadFileToS3 = file => ({ fields, action }) => {
  const form = mapFieldsToForm(fields);

  return wretch(action)
    .formData({
      ...form,
      file
    })
    .post()
    .res()
    .then(() => ({
      file: form.key
    }));
};

export default function uploadFile(driverId, file) {
  return getS3Data(driverId).then(uploadFileToS3(file));
}
