import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Link } from '@material-ui/core';
import { colors } from '@loggi/mar';
import Form from 'view/templates/base-form';
import TEXTS from './constants';

const UserRestoreComponent = ({
  onChange,
  onSubmit,
  context,
  disableSubmit
}) => {
  const { fields, firstName, lastMileCompanyName } = context;
  const title = `${TEXTS.title} ${firstName}!`;
  const subtitle = (
    <>
      <Typography style={{ color: colors.smoke[700] }} variant="subtitle2">
        {TEXTS.subtitle[0]} <b>{lastMileCompanyName}</b>.
      </Typography>
      <Typography paragraph />
      <Typography style={{ color: colors.smoke[700] }} variant="subtitle2">
        {TEXTS.subtitle[1]}
      </Typography>
    </>
  );
  const termsOfUser = (
    <Typography variant="body1" color="textSecondary">
      {TEXTS.agreement}
      <Link target="_blank" href={TEXTS.termsLink}>
        {TEXTS.termsOfUse}
      </Link>
    </Typography>
  );

  const inputList = [
    {
      name: 'fullName',
      label: TEXTS.fullName
    },
    {
      name: 'email',
      label: TEXTS.email,
      type: 'email'
    },
    {
      name: 'mobile',
      label: TEXTS.phone,
      type: 'tel'
    },
    {
      name: 'terms',
      label: termsOfUser,
      isCheckbox: true
    }
  ];

  return (
    <Form onChange={onChange} onSubmit={onSubmit} fields={fields}>
      <Form.Header title={title} subtitle={subtitle} />
      <Box mt={1}>
        {inputList.map(field => (
          <Box key={field.name} mt={1}>
            <Form.Input key={field.name} field={field} />
          </Box>
        ))}
      </Box>
      <Box mb={3}>
        <Typography style={{ color: colors.smoke[800] }} variant="caption">
          {TEXTS.termsDesc}
        </Typography>
      </Box>

      <Form.Submit text={TEXTS.submit} disabled={disableSubmit} />
    </Form>
  );
};

UserRestoreComponent.propTypes = {
  context: PropTypes.shape({
    fields: PropTypes.objectOf(PropTypes.shape({})),
    lastMileCompanyName: PropTypes.string,
    firstName: PropTypes.string
  }).isRequired,
  disableSubmit: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

UserRestoreComponent.defaultProps = {
  disableSubmit: false
};

export default UserRestoreComponent;
