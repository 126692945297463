import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Interpreter } from 'xstate';
import { useService } from '@xstate/react';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import DocumentHubTemplate from 'view/templates/document-hub';
import SignupLayoutTemplate from 'view/templates/signup-layout';
import LoadingTemplate from 'view/templates/loading';
import LoadingErrorTemplate from 'view/templates/loading-error';
import { STATES } from 'machines/document-hub.machine';
import ErrorCriticalPage from './error-critical';

const DocumentHubPage = ({ context, history }) => {
  const {
    services,
    driver: { firstName, photoUrl, profilePicture }
  } = context;
  const { DocumentHubMachine } = services;
  const [current, send] = useService(DocumentHubMachine);
  const { documents, operationalStatus, hasAlert, errorCode } = current.context;
  const retryDocumentList = () => send(STATES.retry);

  const [loading, setLoading] = useState(false);
  const { signOut } = useAmplifyAuth();
  const logout = async () => {
    setLoading(true);
    await signOut();
  };

  const onBack =
    process.env.REACT_APP_DRIVERS_URL === undefined
      ? () => history.goBack()
      : null;

  const canShowLoggiIcon = process.env.REACT_APP_DRIVERS_URL !== undefined;

  const page = loading
    ? () => <LoadingTemplate />
    : {
        [STATES.loading]: () => <LoadingTemplate />,
        [STATES.success]: () => (
          <DocumentHubTemplate
            status={operationalStatus}
            profilePicture={photoUrl || profilePicture}
            name={firstName}
            documents={documents}
            hasAlert={hasAlert}
            history={history}
            logout={logout}
          />
        ),
        [STATES.error]: () =>
          errorCode === 412 ? (
            <ErrorCriticalPage firstName={firstName} />
          ) : (
            <LoadingErrorTemplate onRetry={retryDocumentList} />
          )
      }[current.value];

  return (
    <SignupLayoutTemplate
      page={page()}
      loggiIcon={canShowLoggiIcon}
      onBack={onBack}
    />
  );
};

DocumentHubPage.propTypes = {
  context: PropTypes.shape({
    driver: PropTypes.shape({
      firstName: PropTypes.string,
      profilePicture: PropTypes.string,
      photoUrl: PropTypes.string
    }).isRequired,
    services: PropTypes.shape({
      DocumentHubMachine: PropTypes.instanceOf(Interpreter)
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }).isRequired
};

export default DocumentHubPage;
