import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button } from '@material-ui/core';
import Form from 'view/templates/base-form';
import TEXTS from './constants';

const DriverGetDriverComponent = ({
  onChange,
  onSubmit,
  onSkip,
  context,
  disableSubmit
}) => {
  const { fields } = context;

  const field = {
    name: 'referralCode',
    label: TEXTS.referralCode,
    inputProps: { maxLength: 6 }
  };

  return (
    <Form onChange={onChange} onSubmit={onSubmit} fields={fields}>
      <Form.Header title={TEXTS.title} subtitle={TEXTS.subtitle} />
      <Box mt={1}>
        <Box mt={1}>
          <Form.Input field={field} />
        </Box>
      </Box>

      <Form.Submit text={TEXTS.submit} disabled={disableSubmit} />

      <Box width="100%" pb={3}>
        <Button
          onClick={onSkip}
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
        >
          {TEXTS.skip}
        </Button>
      </Box>
    </Form>
  );
};

DriverGetDriverComponent.propTypes = {
  context: PropTypes.shape({
    fields: PropTypes.objectOf(PropTypes.shape({}))
  }).isRequired,
  disableSubmit: PropTypes.bool,
  onSkip: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

DriverGetDriverComponent.defaultProps = {
  disableSubmit: false
};

export default DriverGetDriverComponent;
