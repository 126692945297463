import { makeStyles } from '@material-ui/core';
import { colors } from '@loggi/mar';

export const TEXT = {
  TITLE: 'Fazer entregas é nosso negócio.',
  SUBTITLE:
    'Os entregadores cadastrados na Loggi têm aqui uma excelente oportunidade de aumento de renda e ainda têm liberdade para gerenciar o próprio tempo e aceitar e recusar rotas quando quiserem.',
  HELP_ME: 'Me ajuda',
  HELP_ME_LINK:
    'https://ajuda.loggi.com/hc/pt-br/sections/4409970016781-Cadastro-e-conta',
  CREATE_ACCOUNT: 'Criar minha conta',
  LOGIN: 'Já tem conta com a Loggi?',
  LOGIN_ACTION: 'Entrar'
};

export const useStyles = makeStyles(theme => ({
  header: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  headerDesktop: {
    background: colors.gradients.sanches
  },
  whiteText: {
    color: theme.palette.common.white
  },
  contentGrid: {
    width: '100%',
    height: '100%',
    display: 'flex',
    overflowX: 'auto',
    justifyContent: 'center'
  },
  contentText: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start'
  },
  containerPage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  contentPage: {
    [theme.breakpoints.up('md')]: {
      width: '50%'
    },
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  blueIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}));
