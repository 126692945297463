import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { DOCUMENT_PATH } from '../constants';
import DocumentCard from './document-card';

const DocumentCardList = ({ documents, history }) => {
  return (
    <Box my={3}>
      {documents.map(
        ({
          documentStatus,
          documentType,
          documentNotes,
          documentTitle,
          expiringIn,
          blockSubmission,
          helpInstruction
        }) => (
          <DocumentCard
            key={documentType}
            documentStatus={documentStatus}
            documentType={documentType}
            documentTitle={documentTitle}
            documentNotes={documentNotes}
            expiringIn={expiringIn}
            blockSubmission={blockSubmission}
            helpInstruction={helpInstruction}
            onClick={() => history.push(DOCUMENT_PATH(documentType))}
          />
        )
      )}
    </Box>
  );
};

DocumentCardList.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      documentType: PropTypes.string,
      documentStatus: PropTypes.string,
      documentTitle: PropTypes.string,
      isPending: PropTypes.bool,
      expiringIn: PropTypes.number,
      documentNotes: PropTypes.shape({
        instructionDescription: PropTypes.string
      }),
      blockSubmission: PropTypes.bool,
      helpInstruction: PropTypes.string
    })
  ).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default DocumentCardList;
