import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Button } from '@material-ui/core';

import { TEXT, useStyles } from './constants';

export default function EmailVerificationTemplate({
  isDesktop,
  onCheck,
  onRetry,
  email
}) {
  const classes = useStyles();
  const subtitle = TEXT.SUBTITLE.replace('<<EMAIL>>', email);

  return (
    <Box className={classes.contentText}>
      <Box flexGrow={1} alignItems="flex-start">
        <Box width="100%">
          <Box pt={5}>
            <Typography variant="h5">{TEXT.TITLE}</Typography>
          </Box>

          <Box pt={1.5}>
            <Typography variant="subtitle1">{subtitle}</Typography>
          </Box>
        </Box>
      </Box>
      <Box mt={isDesktop ? 5.5 : 3.5} pb={2.5} textAlign="center">
        <Typography
          variant="body2"
          component="span"
          className={classes.link}
          onClick={onRetry}
        >
          {TEXT.RESEND}
        </Typography>
      </Box>
      <Box pb={2.5}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={onCheck}
        >
          {TEXT.VERIFY}
        </Button>
      </Box>
    </Box>
  );
}

EmailVerificationTemplate.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired
};
