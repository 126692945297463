import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Form from 'view/templates/base-form';
import Info from 'view/templates/base-form/info';
import ProfilePictureExample from '../images/profile_picture_example.jpg';
import { TEXTS, useStyles } from '../constants';

const InitialUploadForm = ({ onSubmit }) => {
  const styles = useStyles();
  const { TITLE, SUBTITLE, EXAMPLE, INFO_ITEMS, INFO_TITLE } = TEXTS.INITIAL;
  return (
    <Form>
      <Form.Header title={TITLE} subtitle={SUBTITLE} />

      <Box
        data-testid="initial-form"
        pb={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <img
          alt="Foto de perfil"
          src={ProfilePictureExample}
          className={`${styles.image} ${styles.example}`}
        />

        <Typography variant="body2">
          <Box component="span" pt={1}>
            {EXAMPLE}
          </Box>
        </Typography>
      </Box>

      <Info items={INFO_ITEMS} title={INFO_TITLE} />

      <Form.Submit text="Tirar foto" onClick={onSubmit} />
    </Form>
  );
};

InitialUploadForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default InitialUploadForm;
