import React from 'react';
import { Interpreter } from 'xstate';
import PropTypes from 'prop-types';
import { useService } from '@xstate/react';
import CnpjFormTemplate from 'view/templates/cnpj-form';
import SignupLayoutTemplate from 'view/templates/signup-layout';
import PAGES from 'routes/pages';
import { FORM_ACTIONS, FORM_STATE } from 'machines/utils/form';
import { isDocumentEditable } from 'shared/utils/documents';
import { DOCUMENT_TYPE } from 'shared/constants/documents';

const CnpjForm = ({ context, history, isDesktop }) => {
  const { services } = context;
  const { CnpjMachine } = services;
  const [current, send] = useService(CnpjMachine);
  const { documents } = current.context;

  const canEditDocument = isDocumentEditable(documents, DOCUMENT_TYPE.CNPJ);
  const invalid = current.matches(
    `${FORM_STATE.editing}.${FORM_STATE.invalid}`
  );
  const submitting = current.matches(FORM_STATE.submitting);
  const disableSubmit = invalid || submitting;

  const goBack = () => history.push(PAGES.DOCUMENT_HUB.path);

  if (current.matches(FORM_STATE.success) || !canEditDocument) {
    goBack();
  }

  const handleChange = data => send(FORM_ACTIONS.change, data);
  const handleSubmit = event => {
    event.preventDefault();
    send(FORM_ACTIONS.submit);
  };

  const defaultPage = (
    <CnpjFormTemplate
      onChange={handleChange}
      onSubmit={handleSubmit}
      context={current.context}
      disableSubmit={disableSubmit}
    />
  );

  return (
    <SignupLayoutTemplate
      isDesktop={isDesktop}
      page={defaultPage}
      onBack={goBack}
    />
  );
};

CnpjForm.propTypes = {
  context: PropTypes.shape({
    services: PropTypes.shape({
      CnpjMachine: PropTypes.instanceOf(Interpreter)
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  isDesktop: PropTypes.bool
};

CnpjForm.defaultProps = {
  history: {},
  isDesktop: false
};

export default CnpjForm;
