import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {
  Box,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { useStyles, TEXTS_FORM } from './constant';

export default function CameraIsBlocked({ modalIsOpen, title, subtitle }) {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(modalIsOpen);
  const closeDialog = () => setOpenDialog(false);

  return (
    <Dialog open={openDialog} onClose={closeDialog}>
      <Box p={3.5}>
        <DialogTitle className={classes.dialogWithoutPadding}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h4">{title}</Typography>
          </Box>
        </DialogTitle>
        <Box mb={3.5}>
          <DialogContent className={classes.dialogWithoutPadding}>
            <Typography variant="body1">{subtitle}</Typography>
          </DialogContent>
        </Box>
        <DialogActions className={classes.dialogWithoutPadding}>
          <Button onClick={closeDialog} color="primary">
            {TEXTS_FORM.OK_GOT_IT}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

CameraIsBlocked.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};
