import * as Sentry from '@sentry/browser';

const env = process.env.REACT_APP_ENV;

if (env === 'production' || env === 'staging') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    environment: env
  });
}
