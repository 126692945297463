import { getAuthorizationTokens } from '@loggi/authentication-lib';

import { getCredentials } from 'infra/storage/credential';
import { isEmbededDriverApp } from 'shared/utils/driver-app';
import { parseResponse, handleError } from '../request';
import { version } from '../../../package.json';

/**
 * @param {Object} request
 * @param {string} request.query - graphql query string
 * @param {Object} request.variables
 */
export default async function graphqlRequest({ query, variables }) {
  const currentUser = getCredentials();
  const url = `${process.env.REACT_APP_LOGGI_WEB_URL}/graphql/`;

  const headers = {
    'Content-Type': 'application/json',
    appVersion: version,
    service: 'driver-signup'
  };

  const { idToken } = await getAuthorizationTokens();

  if (isEmbededDriverApp() && window?.driverAppBridge?.getLWApiKey?.()) {
    headers.Authorization = window?.driverAppBridge?.getLWApiKey?.();
  } else if (idToken) {
    headers.Authorization = `Bearer ${idToken}`;
  }

  if (currentUser && currentUser.email) {
    headers.observabilityId = currentUser.email;
  }

  const config = {
    method: 'POST',
    body: JSON.stringify({ query, variables }),
    headers
  };

  return fetch(url, config)
    .then(parseResponse)
    .catch(handleError);
}
