import React from 'react';
import PropTypes from 'prop-types';
import { Interpreter } from 'xstate';
import { useService } from '@xstate/react';

import { STATES } from 'machines/finish-create-account.machine';
import PAGES from 'routes/pages';
import FinishCreateAccountTemplate from 'view/templates/finish-create-account';
import SignupLayoutTemplate from 'view/templates/signup-layout';

export default function FinishCreateAccountPage({
  isDesktop,
  history,
  context: {
    services: { FinishCreateAccountMachine }
  }
}) {
  const [current, send] = useService(FinishCreateAccountMachine);
  const onBack = () => history.push(PAGES.EMAIL_VERIFICATION.path);
  const onContinue = () => {
    send('AUTHORIZED');
  };

  if (current.matches(STATES.finished)) {
    history.push(PAGES.DOCUMENT_HUB.path);
    return <></>;
  }

  const component = (
    <FinishCreateAccountTemplate
      isDesktop={isDesktop}
      history={history}
      onContinue={onContinue}
      driver={current.context.driver}
    />
  );

  return <SignupLayoutTemplate page={component} onBack={onBack} />;
}

FinishCreateAccountPage.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  context: PropTypes.shape({
    services: PropTypes.shape({
      FinishCreateAccountMachine: PropTypes.instanceOf(Interpreter)
    })
  }).isRequired
};
