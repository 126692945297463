import React from 'react';
import { matchPath } from 'react-router-dom';
import routeWithRedirect from './route-redirect';

export const getMachineByPath = (actualPath, pages) => {
  const [pageEvent, pageInfo] =
    Object.entries(pages).find(([, { path }]) => {
      return matchPath(actualPath, { path, exact: true });
    }) || [];
  const pageName = pageEvent && pageEvent.toLowerCase();
  const machineName =
    pageInfo && pageInfo.machine && Object.keys(pageInfo.machine)[0];

  return {
    machineName,
    pageName,
    pageEvent
  };
};

export const createRouteFactory = ({
  isAuthenticated,
  authorizedRedirectPath,
  unauthorizedRedirectPath,
  pageProps
}) => ([key, page]) => {
  const PublicRoute = routeWithRedirect({
    redirectIf: isAuthenticated,
    redirectTo: authorizedRedirectPath
  });
  const PrivateRoute = routeWithRedirect({
    redirectIf: !isAuthenticated,
    redirectTo: unauthorizedRedirectPath
  });

  const { component: Component, authenticated, path } = page;
  const RouteGuard = authenticated ? PrivateRoute : PublicRoute;
  const { context, isDesktop, history } = pageProps;

  return (
    <RouteGuard key={key} path={path} exact>
      <Component context={context} isDesktop={isDesktop} history={history} />
    </RouteGuard>
  );
};
