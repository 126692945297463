import { Machine, assign } from 'xstate';
import * as yup from 'yup';
import bankAccountsRequest from 'infra/services/bank-accounts';
import bankAccountDataRequest from 'infra/services/bank-accounts-data';
import { sendDriverDocument } from 'infra/services/driver-documents';
import {
  isAccountValid,
  isAgencyValid
} from './utils/form/validators/bank-account';
import {
  bankAccountFormatter,
  bankAgencyFormatter
} from './utils/form/formatters/bank-account';
import {
  emptyField,
  formValid,
  formInvalid,
  changeField,
  validateFields,
  initialContextFromFieldsValues,
  mergeValidationErrors,
  sendErrorNotification,
  sendSuccessNotification,
  FORM_STATE,
  FORM_ACTIONS
} from './utils/form';

export const initialContext = {
  bankAccounts: [],
  fields: {
    bank: emptyField(),
    user_type: emptyField(),
    account_type: emptyField(),
    agency: emptyField(),
    account: emptyField()
  },
  errors: []
};

export const FORM_ERRORS = {
  bank: 'Opa, selecione um banco.',
  user_type: 'Selecione uma titularidade.',
  account_type: 'Eita, selecione um tipo de conta.',
  agency: 'Eita, a agência parece incorreta, tente de novo.',
  account:
    'Opa, a conta ou o dígito parecem incorretos. Confira e tente de novo.'
};

const FORM_NOTIFICATIONS = {
  success: 'Ótimo! A conta foi enviada com sucesso.',
  error: 'Eita! A conta não foi enviada, tente de novo.'
};

const bankAccountSchema = yup.object().shape({
  bank: yup.string().required(FORM_ERRORS.bank),
  user_type: yup.string().required(FORM_ERRORS.user_type),
  account_type: yup.string().required(FORM_ERRORS.account_type),
  agency: yup
    .string()
    .test('agency', FORM_ERRORS.agency, isAgencyValid)
    .required(FORM_ERRORS.agency)
    .transform(bankAgencyFormatter),
  account: yup
    .string()
    .test('account', FORM_ERRORS.account, isAccountValid)
    .required(FORM_ERRORS.account)
    .transform(bankAccountFormatter)
});

const getInitialData = () =>
  Promise.all([bankAccountsRequest(), bankAccountDataRequest()]);

export const guards = {
  formValid,
  formInvalid
};

export const actions = {
  changeField,
  mergeValidationErrors,
  validateFields: validateFields(bankAccountSchema),
  sendErrorNotification: sendErrorNotification(FORM_NOTIFICATIONS.error),
  sendSuccessNotification: sendSuccessNotification(FORM_NOTIFICATIONS.success)
};

const services = {
  getInitialData,
  updateDriverBankAccount: async ({ driver, fields }) =>
    sendDriverDocument(driver.userId, 'bank', {
      bank: {
        bank_number: fields.bank.value,
        agency_number: fields.agency.value,
        account_number: fields.account.value,
        user_type: fields.user_type.value,
        account_type: fields.account_type.value
      }
    })
};

const BankAccountMachine = Machine(
  {
    id: 'bankAccount',
    initial: FORM_STATE.loading,
    context: initialContextFromFieldsValues(initialContext, bankAccountSchema),
    states: {
      [FORM_STATE.loading]: {
        invoke: {
          id: 'getInitialData',
          src: 'getInitialData',
          onDone: {
            actions: [
              assign((_, { data: [bankAccounts, bankAccountData] }) => {
                if (!bankAccountData || bankAccountData?.account.length === 0) {
                  return {
                    bankAccounts
                  };
                }

                const { fields } = initialContext;
                Object.keys(bankAccountData).forEach(key => {
                  if (bankAccountData[key]) {
                    fields[key] = {
                      ...emptyField(),
                      value: bankAccountData[key]
                    };
                  }

                  return key;
                });

                return {
                  bankAccounts,
                  fields
                };
              })
            ],
            target: FORM_STATE.editing
          },
          onError: {
            actions: [
              assign(() => {
                const bankAccounts = [];
                const { fields } = initialContext;
                return {
                  bankAccounts,
                  fields
                };
              })
            ],
            target: FORM_STATE.failure
          }
        }
      },
      [FORM_STATE.editing]: {
        initial: FORM_STATE.invalid,
        states: {
          [FORM_STATE.invalid]: {
            on: {
              '': [{ target: FORM_STATE.valid, cond: 'formValid' }]
            }
          },
          [FORM_STATE.valid]: {
            on: {
              '': [{ target: FORM_STATE.invalid, cond: 'formInvalid' }]
            }
          }
        },
        on: {
          [FORM_ACTIONS.submit]: {
            target: FORM_STATE.submitting,
            cond: 'formValid'
          },
          [FORM_ACTIONS.change]: {
            actions: ['changeField', 'validateFields']
          }
        }
      },
      [FORM_STATE.submitting]: {
        invoke: {
          id: 'updateDriverBankAccount',
          src: 'updateDriverBankAccount',
          onDone: {
            target: FORM_STATE.success,
            actions: 'sendSuccessNotification'
          },
          onError: {
            target: FORM_STATE.editing,
            actions: ['mergeValidationErrors', 'sendErrorNotification']
          }
        }
      },
      [FORM_STATE.success]: {
        type: 'final'
      },
      [FORM_STATE.failure]: {
        on: {
          [FORM_ACTIONS.retry]: FORM_STATE.loading
        }
      }
    }
  },
  {
    guards,
    actions,
    services
  }
);

export default BankAccountMachine;
