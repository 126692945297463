import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from '../utils';

const AlertCard = ({ testId, title, text }) => {
  const style = useStyles();

  return (
    <Box
      data-testid={testId}
      className={`${style.documentCardError}`}
      minHeight="100px"
      mt={1.25}
      py={2.5}
      borderRadius={8}
      display="flex"
    >
      <Box mt={3.5} ml={2.5} mr={2} />
      <Box display="flex" flexDirection="column">
        <Typography component="span" variant="h6">
          <Box fontWeight="fontWeightBold">{title}</Box>
        </Typography>
        <Typography component="span" variant="body1">
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

AlertCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  testId: PropTypes.string
};

AlertCard.defaultProps = {
  testId: null
};

export default AlertCard;
