import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';

import LOADING_ERROR_TEXT from './constants';

function LoadingErrorTemplate({ onRetry }) {
  return (
    <Box
      width="100%"
      minHeight="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      bgcolor={colors.root[0]}
      py={5.5}
      px={3.5}
      data-testid="loading-error"
    >
      <Box pt={5.5} maxWidth="460px" textAlign="center">
        <Box px={2.5} pb={2.5}>
          <Typography variant="h4">{LOADING_ERROR_TEXT.TITLE}</Typography>
        </Box>

        <Box px={2.5} pb={2.5} color={colors.smoke[700]}>
          <Typography variant="body1">{LOADING_ERROR_TEXT.SUBTITLE}</Typography>
        </Box>

        <Box px={2.5} pb={4.5} color={colors.smoke[700]}>
          <Typography variant="body1">{LOADING_ERROR_TEXT.RETRY}</Typography>
        </Box>

        <Box boxShadow={3} borderRadius={26}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onRetry}
            data-testid="retryButton"
          >
            {LOADING_ERROR_TEXT.BUTTON}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

LoadingErrorTemplate.propTypes = {
  onRetry: PropTypes.func.isRequired
};

export default React.memo(LoadingErrorTemplate);
