import { assign, spawn } from 'xstate';

export const getMachineName = page =>
  page.machine && Object.keys(page.machine)[0];

export const pageState = (states, [key, page]) => {
  const machineName = getMachineName(page);
  return {
    ...states,
    [key.toLowerCase()]: !machineName ? {} : { entry: key.toLowerCase() }
  };
};

export const pageAction = (states, [key, page]) => {
  const machineName = getMachineName(page);
  return {
    ...states,
    [key.toLowerCase()]: !machineName
      ? {}
      : assign({
          services: context => ({
            ...context.services,
            [machineName]: spawn(
              page.machine[machineName].withContext({
                ...page.machine[machineName].context,
                ...context
              })
            )
          })
        })
  };
};

export const pageEvent = (actions, actionName) => ({
  ...actions,
  [actionName]: `.${actionName.toLowerCase()}`
});

const pageMachineConfig = pages => ({
  pagesStates: Object.entries(pages).reduce(pageState, {}),
  pagesActions: Object.entries(pages).reduce(pageAction, {}),
  pagesEvents: Object.keys(pages).reduce(pageEvent, {})
});

export default pageMachineConfig;
