import React from 'react';
import PropTypes from 'prop-types';
import Form from 'view/templates/base-form';
import TEXTS from './constants';

const LicensePlateFormTemplate = ({
  onChange,
  onSubmit,
  context,
  disableSubmit
}) => {
  const { fields } = context;
  const inputList = [
    {
      name: 'licensePlate',
      placeholder: TEXTS.FORM.LICENSE_PLATE,
      inputProps: { maxLength: 8 }
    }
  ];

  return (
    <Form onChange={onChange} onSubmit={onSubmit} fields={fields}>
      <Form.Header title={TEXTS.TITLE} subtitle={TEXTS.SUBTITLE} />

      {inputList.map(field => (
        <Form.Input key={field.name} field={field} />
      ))}

      <Form.Submit text="Enviar" disabled={disableSubmit} />
    </Form>
  );
};

LicensePlateFormTemplate.propTypes = {
  context: PropTypes.shape({
    fields: PropTypes.objectOf(PropTypes.shape({}))
  }).isRequired,
  disableSubmit: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

LicensePlateFormTemplate.defaultProps = {
  disableSubmit: false
};

export default LicensePlateFormTemplate;
