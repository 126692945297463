import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) => ({
  list: {
    padding: 0
  },
  listIcon: {
    minWidth: 'auto',
    paddingRight: spacing(1)
  },
  listItem: {
    padding: 0
  }
}));

export const TEXTS = {
  TITLE: 'Defina a conta bancária',
  SUBTITLE:
    'Informe uma conta no seu nome para receber os repasses das suas entregas.',
  INFO_TITLE: 'Atenção: não fazemos repasses para:',
  INFO_ITEMS: [
    'Conta Fácil da Caixa',
    'Conta salário',
    'Conta poupança que não aceite TED'
  ],
  FORM: {
    BANK: 'Banco',
    OWNER: 'Titularidade',
    TYPE: 'Tipo de conta',
    AGENCY: 'Agência',
    ACCOUNT: 'Conta com dígito verificador',
    DIGIT: 'Dígito verificador'
  },
  TOOLTIP: 'Complete com 0 à esquerda caso sua agência tenha apenas 3 números.'
};

export const OWNER_TYPES_OPTIONS = isMeiApproved => [
  { value: 1, label: 'Conta pessoal' },
  {
    value: 2,
    disabled: !isMeiApproved,
    label: isMeiApproved
      ? 'Conta empresarial'
      : 'Conta empresarial (Aguarde aprovação do MEI)'
  }
];

export const ACCOUNT_TYPE_OPTIONS = [
  { value: 1, label: 'Poupança' },
  { value: 2, label: 'Conta corrente' }
];
