import graphqlRequest from 'infra/graphql-request/';

const bankAccountsQuery = `
  query bankAccount {
    bankAccounts {
      code
      name
    }
  }
`;

export default function bankAccounts() {
  return graphqlRequest({
    query: bankAccountsQuery,
    variables: {}
  }).then(({ data }) => data.bankAccounts);
}
