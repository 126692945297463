const TEXTS = {
  TITLE: 'Digite a placa do veículo',
  SUBTITLE:
    'Informe a placa do veículo que você vai utilizar para fazer suas entregas.',
  FORM: {
    LICENSE_PLATE: 'Placa',
    TRANSPORT_TYPE: 'Tipo de veículo'
  }
};

export default TEXTS;
