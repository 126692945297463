const TEXTS = {
  title: 'Olá,',
  subtitle: [
    'Uma transportadora te convidou para fazer parte da equipe ',
    'Revise suas informações para completar seu cadastro.'
  ],
  fullName: 'Nome completo',
  email: 'E-mail',
  phone: 'Celular',
  agreement: 'Li e concordo com os ',
  termsOfUse: 'Termos de Uso',
  termsLink: 'https://www.loggi.com/termos-de-uso-entregadores/',
  termsDesc:
    'Também autorizo a Loggi e seus representantes a falarem comigo por e-mail, telefone, WhatsApp ou SMS nos contatos acima.',
  submit: 'Completar cadastro'
};

export default TEXTS;
