import { Machine } from 'xstate';
import * as yup from 'yup';
import { sendDriverDocument } from 'infra/services/driver-documents';

import { validate, format } from 'cnpj';
import {
  emptyField,
  formValid,
  formInvalid,
  changeField,
  validateFields,
  initialContextFromFieldsValues,
  mergeValidationErrors,
  sendErrorNotification,
  sendSuccessNotification,
  FORM_STATE,
  FORM_ACTIONS
} from './utils/form';

export const initialContext = {
  fields: {
    cnpj: emptyField()
  },
  errors: []
};

const FORM_ERRORS = {
  cnpj: 'Eita! Digite um CNPJ válido para criar seu cadastro.'
};

const FORM_NOTIFICATIONS = {
  success: 'Tudo certo! O MEI foi enviado com sucesso! :)',
  error: 'Eita! Aconteceu alguma coisa e o MEI não foi. Tente de novo.'
};

const cnpjSchema = yup.object().shape({
  cnpj: yup
    .string()
    .test('cnpj', FORM_ERRORS.cnpj, validate)
    .required(FORM_ERRORS.cnpj)
    .transform(format)
});

export const guards = {
  formValid,
  formInvalid
};

export const actions = {
  changeField,
  mergeValidationErrors,
  validateFields: validateFields(cnpjSchema),
  sendErrorNotification: sendErrorNotification(FORM_NOTIFICATIONS.error, {
    getErrorFromEvent: true
  }),
  sendSuccessNotification: sendSuccessNotification(FORM_NOTIFICATIONS.success)
};

const services = {
  updateCnpj: ({ driver, fields }) =>
    sendDriverDocument(driver.userId, 'cnpj', {
      cnpj: {
        number: fields.cnpj.value.replace(/\D/g, '')
      }
    })
};
const cnpjMachine = Machine(
  {
    id: 'cnpj',
    initial: 'editing',
    context: initialContextFromFieldsValues(initialContext, cnpjSchema),
    states: {
      [FORM_STATE.editing]: {
        initial: FORM_STATE.invalid,
        states: {
          [FORM_STATE.invalid]: {
            on: {
              '': [{ target: FORM_STATE.valid, cond: 'formValid' }]
            }
          },
          [FORM_STATE.valid]: {
            on: {
              '': [{ target: FORM_STATE.invalid, cond: 'formInvalid' }]
            }
          }
        },
        on: {
          [FORM_ACTIONS.submit]: {
            target: FORM_STATE.submitting,
            cond: 'formValid'
          },
          [FORM_ACTIONS.change]: {
            actions: ['changeField', 'validateFields']
          }
        }
      },
      [FORM_STATE.submitting]: {
        invoke: {
          id: 'updateCnpj',
          src: 'updateCnpj',
          onDone: {
            target: FORM_STATE.success,
            actions: 'sendSuccessNotification'
          },
          onError: {
            target: FORM_STATE.editing,
            actions: ['mergeValidationErrors', 'sendErrorNotification']
          }
        }
      },
      [FORM_STATE.success]: {
        type: 'final'
      }
    }
  },
  {
    guards,
    actions,
    services
  }
);

export default cnpjMachine;
