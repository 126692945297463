const FORMAT_PATTERN = new RegExp(/^(.*)(.$)$/);

export const bankAccountFormatter = value => {
  const account = value.replace(/-/g, '');
  return account.length > 1
    ? account.replace(FORMAT_PATTERN, '$1-$2')
    : account;
};

export const bankAgencyFormatter = value => {
  const agency = value.replace(/-/g, '');
  return agency.length > 4 ? agency.replace(FORMAT_PATTERN, '$1-$2') : agency;
};
