import get from 'lodash/get';
import graphqlRequest from '../graphql-request';

const requestBankAccountData = `
  {
    viewer {
      driver {
        bankAccount {
            accountNo
            agencyNo
            bank {
                code
            }
            cnpj
            cpf
            name
            status
            type
            userType
        }
      }
    }
  }
`;

export default function bankAccountData() {
  return graphqlRequest({
    query: requestBankAccountData
  }).then(({ data, errors }) => {
    const { driver } = data.viewer;

    if (driver) {
      const bankAccount = get(driver, 'bankAccount', null);
      return Promise.resolve({
        bank: get(bankAccount, 'bank.code', null),
        agency: get(bankAccount, 'agencyNo', null),
        account: get(bankAccount, 'accountNo', ''),
        user_type: get(bankAccount, 'userType', '') === 'mei' ? 2 : 1,
        account_type: get(bankAccount, 'type', '') === 'CORRENTE' ? 2 : 1
      });
    }

    const errorResponse = { errors };
    return Promise.reject(errorResponse);
  });
}
