import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import Form from 'view/templates/base-form';
import Submit from 'view/templates/base-form/submit/picture';
import Picture from '../components/picture';
import { TEXTS, FORM_TEST_ID } from '../constants';

const ReviewUploadForm = ({ context, onSubmit, onCancel }) => {
  const {
    fields: { file }
  } = context;
  const { TITLE, SUBTITLE } = TEXTS.REVIEW;
  const picture = URL.createObjectURL(file.value);
  return (
    <Form>
      <Form.Header title={TITLE} subtitle={SUBTITLE} />

      <Box
        data-testid={FORM_TEST_ID.REVIEW}
        pb={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Picture picture={picture} />
      </Box>

      <Submit onClick={onSubmit} onClear={onCancel} />
    </Form>
  );
};

ReviewUploadForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  context: PropTypes.shape({
    fields: PropTypes.shape({
      file: PropTypes.shape({
        value: PropTypes.shape({})
      })
    })
  }).isRequired
};

export default ReviewUploadForm;
