const TEXTS = {
  title: 'Recebeu um código de indicação de outro entregador?',
  subtitle:
    'Caso ninguém tenha enviado nenhum código de indicação, continue o cadastro sem código, normalmente.',
  referralCode: 'Código',
  submit: 'Validar código',
  skip: 'Continuar sem código'
};

export default TEXTS;
