import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Form from 'view/templates/base-form';
import Picture from '../components/picture';
import { TEXTS, FORM_TEST_ID } from '../constants';

const ErrorUploadForm = ({ context, onSubmit }) => {
  const { reason, driver } = context;
  const { TITLE, SUBTITLE } = TEXTS.ERROR(reason);
  return (
    <Form>
      <Form.Header title={TITLE} subtitle={SUBTITLE} />

      <Box
        data-testid={FORM_TEST_ID.ERROR}
        pb={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Picture picture={driver.cnh} />
      </Box>

      <Form.Submit text="Enviar outra" onClick={onSubmit} />
    </Form>
  );
};

ErrorUploadForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  context: PropTypes.shape({
    reason: PropTypes.string,
    driver: PropTypes.shape({
      cnh: PropTypes.string
    })
  }).isRequired
};

export default ErrorUploadForm;
