import React from 'react';
import { Machine, send, sendParent, assign } from 'xstate';
import * as yup from 'yup';
import CPF from 'cpf';

import { Error } from '@material-ui/icons';
import { colors } from '@loggi/mar';

import allOperatingCities from 'infra/services/all-operating-cities';
import availableTransportTypes from 'infra/services/available-transport-types';
import requestPhoneVerification from 'infra/services/request-phone-verification';
import validateSignupFields from 'infra/services/validate-signup-fields';
import * as storage from 'infra/storage/credential';

import { DRIVER_INFO_FORM_MESSAGES } from './utils/errors-message';
import {
  emptyField,
  formValid,
  formInvalid,
  changeField,
  validateFields,
  validateFieldsAndMergeErrors,
  fieldsValuesFromContext,
  fieldsFromFieldValues
} from './utils/form';
import isMobilePhoneValid from './utils/form/validators/mobile-phone';
import {
  mobilePhoneFormatter,
  removeMobilePhoneFormatter
} from './utils/form/formatters/mobile-phone';

export const STATES = {
  loading: 'loading',
  editing: 'editing',
  valid: 'valid',
  invalid: 'invalid',
  failure: 'failure',
  submiting: 'submiting',
  phoneVerification: 'phoneVerification',
  finished: 'finished'
};

export const initialContext = {
  cities: [],
  transportTypes: [],
  fields: {
    name: emptyField(),
    cpf: emptyField(),
    email: emptyField(),
    mobileNumber: emptyField(),
    citySlug: emptyField(),
    transportType: emptyField(),
    terms: emptyField()
  },
  errors: []
};

const getInitialData = () =>
  Promise.all([allOperatingCities(), availableTransportTypes()]);

const driverInfoSchema = yup.object().shape({
  name: yup.string().required(DRIVER_INFO_FORM_MESSAGES.name),
  cpf: yup
    .string()
    .test('valid-cpf', DRIVER_INFO_FORM_MESSAGES.cpf, value =>
      CPF.isValid(value)
    )
    .required(DRIVER_INFO_FORM_MESSAGES.cpfEmpty)
    .transform(value => {
      try {
        return CPF.format(value);
      } catch (_) {
        return value;
      }
    }),
  email: yup
    .string()
    .email(DRIVER_INFO_FORM_MESSAGES.email)
    .required(DRIVER_INFO_FORM_MESSAGES.emailEmpty),
  mobileNumber: yup
    .string()
    .test(
      'mobileNumber',
      DRIVER_INFO_FORM_MESSAGES.mobileNumber,
      isMobilePhoneValid
    )
    .required(DRIVER_INFO_FORM_MESSAGES.mobileNumberEmpty)
    .transform(mobilePhoneFormatter),
  citySlug: yup.string().required(DRIVER_INFO_FORM_MESSAGES.citySlug),
  transportType: yup.string().required(DRIVER_INFO_FORM_MESSAGES.transportType),
  terms: yup.boolean().oneOf([true], DRIVER_INFO_FORM_MESSAGES.terms)
});

export const initialContextFromFieldsValues = driverInfo => {
  const fields = validateFieldsAndMergeErrors(
    driverInfoSchema,
    driverInfo
      ? {
          fields: fieldsFromFieldValues(driverInfo),
          errors: []
        }
      : initialContext
  );
  return {
    cities: [],
    transportTypes: [],
    fields,
    errors: []
  };
};

export const guards = {
  formValid,
  formInvalid
};

const validateSignUpFieldsQuery = context => {
  const { mobileNumber, ...fields } = fieldsValuesFromContext(context);
  return validateSignupFields({
    mobileNumber: removeMobilePhoneFormatter(mobileNumber),
    ...fields
  });
};

const requestPhoneVerificationQuery = ({ fields }) =>
  requestPhoneVerification({
    mobileNumber: removeMobilePhoneFormatter(fields.mobileNumber.value)
  });

const mergeValidationErrors = ({ fields }, event) => {
  const errorFields = event.data.errors.filter(item => 'field' in item);
  const commonErrors = event.data.errors.filter(item => !('field' in item));

  return {
    fields: errorFields.reduce((newFields, item) => {
      const fieldName = item.field;

      return {
        ...newFields,
        [fieldName]: {
          ...fields[fieldName],
          errors: [item.message]
        }
      };
    }, fields),
    errors: commonErrors
  };
};

export const actions = {
  changeField,
  validateFields: validateFields(driverInfoSchema),
  sendErrorNotification: sendParent(context => {
    const hasErrorFields = Object.values(context.fields).some(
      item => item.errors.length
    );

    const genericError = hasErrorFields
      ? 'Tem algo errado no cadastro. Confira.'
      : 'Falha no sistema. Tente de novo.';

    const message = context.errors.length
      ? context.errors[0].message
      : genericError;

    return {
      type: 'NOTIFICATION.SET',
      color: colors.red[500],
      startAdornment: <Error fontSize="large" />,
      message
    };
  }),
  setDriver: sendParent(context => {
    const data = fieldsValuesFromContext(context);
    return {
      type: 'SETUSER',
      data: {
        ...data,
        firstName: data.name.split(' ')[0]
      }
    };
  }),
  mergeValidationErrors: assign((context, fields) =>
    mergeValidationErrors(context, fields)
  ),
  setCredentials: context => {
    const data = fieldsValuesFromContext(context);
    return storage.storeCredentials({
      name: data.name,
      email: data.email,
      cpf: data.cpf,
      citySlug: data.citySlug,
      mobileNumber: data.mobileNumber,
      apiKey: null,
      transportType: data.transportType,
      gcmId: ''
    });
  }
};

const services = {
  validateSignUpFieldsQuery,
  requestPhoneVerificationQuery,
  getInitialData
};

const CreateAccountMachine = Machine(
  {
    id: 'create account',
    initial: STATES.loading,
    context: initialContextFromFieldsValues(),
    on: {
      EDITING: STATES.editing,
      FAILURE: STATES.failure,
      SUBMITING: STATES.submiting,
      PHONEVERIFICATION: STATES.phoneVerification,
      FINISHED: STATES.finished
    },
    states: {
      [STATES.loading]: {
        invoke: {
          id: 'getInitialData',
          src: 'getInitialData',
          onDone: {
            actions: [
              assign((_, { data: [cities, transportTypes] }) => {
                return {
                  cities,
                  transportTypes
                };
              }),
              send(STATES.editing.toUpperCase())
            ]
          },
          onError: {
            actions: [
              assign(() => {
                const cities = [];
                const transportTypes = [];
                return {
                  cities,
                  transportTypes
                };
              }),
              send(STATES.failure.toUpperCase())
            ]
          }
        }
      },
      [STATES.failure]: {
        on: {
          RETRY: STATES.loading
        }
      },
      [STATES.editing]: {
        initial: STATES.invalid,
        states: {
          [STATES.invalid]: {
            on: {
              '': [{ target: STATES.valid, cond: 'formValid' }]
            }
          },
          [STATES.valid]: {
            on: {
              '': [{ target: STATES.invalid, cond: 'formInvalid' }]
            }
          }
        },
        on: {
          SUBMIT: {
            actions: ['setCredentials'],
            target: STATES.submiting,
            cond: 'formValid'
          },
          CHANGE: {
            actions: ['changeField', 'validateFields']
          }
        }
      },
      [STATES.submiting]: {
        invoke: {
          id: 'validateSignupFieldsQuery',
          src: 'validateSignUpFieldsQuery',
          onDone: {
            target: STATES.phoneVerification
          },
          onError: {
            target: STATES.editing,
            actions: ['mergeValidationErrors', 'sendErrorNotification']
          }
        }
      },
      [STATES.phoneVerification]: {
        entry: ['setDriver'],
        invoke: {
          id: 'requestPhoneVerificationQuery',
          src: 'requestPhoneVerificationQuery',
          onDone: {
            target: STATES.finished
          },
          onError: {
            target: STATES.editing,
            actions: ['mergeValidationErrors', 'sendErrorNotification']
          }
        }
      },
      [STATES.finished]: {
        type: 'final'
      }
    }
  },
  {
    guards,
    actions,
    services
  }
);

export default CreateAccountMachine;
