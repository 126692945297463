import { Machine, assign } from 'xstate';
import uploadFile from 'infra/services/upload-file';
import { sendDriverDocument } from 'infra/services/driver-documents';
import getDriverCnh from 'infra/services/get-driver-cnh';
import { DOCUMENT_STATUS, DOCUMENT_TYPE } from 'shared/constants/documents';
import { getDocumentReason } from 'shared/utils/documents';

import {
  emptyField,
  changeField,
  mergeValidationErrors,
  sendErrorNotification,
  sendSuccessNotification,
  FORM_STATE,
  FORM_ACTIONS
} from './utils/form';

export const initialContext = {
  fields: {
    file: emptyField(),
    token: emptyField()
  },
  errors: [],
  reason: null,
  tokenFlowCallback: null
};

const FORM_NOTIFICATIONS = {
  success: 'Beleza! A foto foi enviada com sucesso.',
  error: 'Opa! A foto não foi enviada, tente de novo.'
};

export const guards = {
  documentInvalid: ctx => {
    return ctx.documents.some(
      ({ documentType, documentStatus }) =>
        documentType === DOCUMENT_TYPE.CNH &&
        documentStatus === DOCUMENT_STATUS.INVALID
    );
  },
  documentValid: ctx => {
    return ctx.documents.some(
      ({ documentType, documentStatus }) =>
        documentType === DOCUMENT_TYPE.CNH &&
        documentStatus === DOCUMENT_STATUS.VALID
    );
  },
  formValid: ctx => ctx.fields.file.value || ctx.fields.token.value,
  formInvalid: ctx => !ctx.fields.file.value,
  useTokenFlow: () =>
    window.driverAppBridge?.useSdkIdwallFlow !== undefined &&
    window.driverAppBridge.useSdkIdwallFlow()
};

export const actions = {
  changeField,
  mergeValidationErrors,
  sendErrorNotification: sendErrorNotification(FORM_NOTIFICATIONS.error, {
    getErrorFromEvent: true
  }),
  sendSuccessNotification: sendSuccessNotification(FORM_NOTIFICATIONS.success),
  setReason: assign({
    reason: ctx => getDocumentReason(ctx.documents, DOCUMENT_TYPE.CNH)
  }),
  setDriverDocuments: assign({
    driver: (ctx, { data }) => ({
      ...ctx.driver,
      cnh: data.cnh
    })
  }),
  setTokenFlowCallback: assign({
    tokenFlowCallback: (_, event) => event.tokenFlowCallback
  }),
  startTokenFlow: ctx => {
    window.driverAppBridgeTokenCallback = ctx.tokenFlowCallback;
    window.driverAppBridge.openIdwallFlow('driverAppBridgeTokenCallback');
  },
  clearToken: assign({
    fields: ctx => ({ ...ctx.fields, token: emptyField() })
  })
};

const services = {
  getDriverCnh,
  updateDriverCnh: async ({ driver, fields }) => {
    const { file } = fields.file.value
      ? await uploadFile(driver.userId, fields.file.value)
      : { file: null };
    const tokenValue = fields.token.value;

    return sendDriverDocument(driver.userId, 'cnh', {
      cnh: {
        filepath: file,
        externalToken: tokenValue
      }
    });
  }
};

const CnhMachine = Machine(
  {
    id: 'Cnh',
    initial: FORM_STATE.loading,
    context: initialContext,
    on: {
      [FORM_ACTIONS.edit]: {
        target: FORM_STATE.editing,
        actions: 'setTokenFlowCallback'
      }
    },
    states: {
      [FORM_STATE.loading]: {
        invoke: {
          id: 'getDriverCnh',
          src: 'getDriverCnh',
          onDone: {
            target: FORM_STATE.idle,
            actions: 'setDriverDocuments'
          },
          onError: {
            target: FORM_STATE.error,
            actions: [
              assign({
                errors: (_, { data }) => {
                  return data.errors;
                }
              })
            ]
          }
        }
      },
      [FORM_STATE.idle]: {
        on: {
          '': [
            { target: FORM_STATE.documentInvalid, cond: 'documentInvalid' },
            { target: FORM_STATE.documentValid, cond: 'documentValid' }
          ]
        }
      },
      [FORM_STATE.editing]: {
        initial: FORM_STATE.invalid,
        states: {
          [FORM_STATE.invalid]: {
            on: {
              '': [
                { target: FORM_STATE.loadingToken, cond: 'useTokenFlow' },
                { target: FORM_STATE.valid, cond: 'formValid' }
              ]
            }
          },
          [FORM_STATE.valid]: {
            on: {
              '': [{ target: FORM_STATE.invalid, cond: 'formInvalid' }]
            }
          },
          [FORM_STATE.loadingToken]: {
            entry: 'startTokenFlow'
          }
        },
        on: {
          [FORM_ACTIONS.submit]: {
            target: FORM_STATE.submitting,
            cond: 'formValid'
          },
          [FORM_ACTIONS.change]: {
            actions: 'changeField'
          },
          [FORM_ACTIONS.reload]: {
            target: FORM_STATE.idle
          }
        }
      },
      [FORM_STATE.submitting]: {
        invoke: {
          id: 'updateDriverCnh',
          src: 'updateDriverCnh',
          onDone: {
            target: FORM_STATE.success,
            actions: 'sendSuccessNotification'
          },
          onError: [
            {
              target: FORM_STATE.idle,
              actions: [
                'mergeValidationErrors',
                'sendErrorNotification',
                'clearToken'
              ],
              cond: 'useTokenFlow'
            },
            {
              target: FORM_STATE.editing,
              actions: [
                'mergeValidationErrors',
                'sendErrorNotification',
                'clearToken'
              ]
            }
          ]
        }
      },
      [FORM_STATE.success]: {
        type: 'final'
      },
      [FORM_STATE.error]: {
        on: {
          [FORM_ACTIONS.retry]: FORM_STATE.loading
        }
      },
      [FORM_STATE.documentValid]: {
        type: 'final'
      },
      [FORM_STATE.documentInvalid]: {
        entry: 'setReason'
      }
    }
  },
  {
    guards,
    actions,
    services
  }
);

export default CnhMachine;
