export const DRIVER_INFO_FORM_MESSAGES = {
  name:
    'Este nome parece inválido. Escreva da forma que aparece nos seus documentos e tente de novo.',
  cpf: 'CPF inválido. Verifique a numeração com cuidado e tente de novo.',
  cpfEmpty: 'Eita! Digite seu CPF para se cadastrar',
  email: 'E-mail inválido. Verifique se escreveu corretamente e tente de novo.',
  emailEmpty: 'Eita! Você precisa informar um e-mail',
  mobileNumber:
    'Número inválido. Verifique se digitou corretamente e tente de novo.',
  mobileNumberEmpty: 'Eita! Ficou faltando seu telefone',
  citySlug: 'Selecione uma cidade.',
  transportType: 'Selecione um tipo de veículo.',
  terms: 'Opa! Para continuar, você precisa aceitar os Termos e Condições.'
};

const errorsMessage = { DRIVER_INFO_FORM_MESSAGES };

export default errorsMessage;
