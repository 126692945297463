const PATTERN_BR = new RegExp(/^([a-zA-Z]{3})-([0-9]{4})$/);
const PATTERN_MERCOSUL = new RegExp(/^([a-zA-Z]{3}[0-9][a-zA-Z][0-9]{2})$/);
const FORMAT_PATTERN = new RegExp(/^([a-zA-Z]{3})([0-9]{4})$/);

export default function licensePlate(value) {
  const plate = value.toUpperCase();

  if (!PATTERN_BR.test(plate) || !PATTERN_MERCOSUL.test(plate)) {
    if (plate.length <= 3) {
      return plate.replace(/([^a-zA-Z])/g, '');
    }
  }

  if (FORMAT_PATTERN.test(plate)) {
    return plate.replace(/-/g, '').replace(FORMAT_PATTERN, '$1-$2');
  }

  return plate;
}
