import React from 'react';
import PropTypes from 'prop-types';
import PAGES from 'routes/pages';
import DocumentHubWaitingTemplate from 'view/templates/document-hub/waiting';
import SignupLayoutTemplate from 'view/templates/signup-layout';

const DocumentHubWaitingPage = ({ context, isDesktop, history }) => {
  const { documents } = context;
  const onBack = () => history.push(PAGES.DOCUMENT_HUB.path);

  const Page = (
    <DocumentHubWaitingTemplate documents={documents} history={history} />
  );
  return (
    <SignupLayoutTemplate onBack={onBack} isDesktop={isDesktop} page={Page} />
  );
};

DocumentHubWaitingPage.propTypes = {
  context: PropTypes.shape({
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        documentType: PropTypes.string,
        documentStatus: PropTypes.string,
        documentTitle: PropTypes.string,
        isPending: PropTypes.bool,
        expiringIn: PropTypes.number,
        documentNotes: PropTypes.shape({
          instructionDescription: PropTypes.string
        }),
        blockSubmission: PropTypes.bool,
        helpInstruction: PropTypes.string
      })
    )
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  isDesktop: PropTypes.bool.isRequired
};

export default DocumentHubWaitingPage;
