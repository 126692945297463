import get from 'lodash/get';
import {
  DOCUMENT_STATUS,
  DOCUMENT_TYPE,
  DOCUMENT_TYPE_DISABLED
} from '../constants/documents';

const DOCUMENTS_CAN_EDIT_VALID = [
  DOCUMENT_TYPE.PROFILE_PICTURE,
  DOCUMENT_TYPE.CNH,
  DOCUMENT_TYPE.BANK_ACCOUNT,
  DOCUMENT_TYPE.LICENSE_PLATE
];
const DOCUMENTS_IMAGE_EDIT_STATUS = [
  DOCUMENT_STATUS.ABOUT_TO_EXPIRE,
  DOCUMENT_STATUS.EXPIRED,
  DOCUMENT_STATUS.PENDING,
  DOCUMENT_STATUS.INVALID,
  DOCUMENT_STATUS.VALID
];
const DOCUMENTS_EDIT_STATUS = [
  DOCUMENT_STATUS.ABOUT_TO_EXPIRE,
  DOCUMENT_STATUS.EXPIRED,
  DOCUMENT_STATUS.PENDING,
  DOCUMENT_STATUS.INVALID,
  DOCUMENT_STATUS.VALID
];

export const isDocumentDisabled = documentType =>
  DOCUMENT_TYPE_DISABLED.ANTT === documentType;

export const canEditDocument = (documentStatus, documentType) => {
  if (isDocumentDisabled(documentType)) {
    return false;
  }

  return DOCUMENTS_CAN_EDIT_VALID.includes(documentType)
    ? DOCUMENTS_IMAGE_EDIT_STATUS.includes(documentStatus)
    : DOCUMENTS_EDIT_STATUS.includes(documentStatus);
};

export const isDocumentEditable = (documents, documentType) => {
  if (!documents) return false;
  const { documentStatus } = documents.find(
    doc => doc.documentType === documentType
  );
  return canEditDocument(documentStatus, documentType);
};

export const isMeiValid = context =>
  context.documents.some(
    ({ documentType, documentStatus }) =>
      documentType === DOCUMENT_TYPE.CNPJ &&
      documentStatus === DOCUMENT_STATUS.VALID
  );

export const getDocumentReason = (documents, documentType) => {
  const FULL_STOP = new RegExp(/\.$/);
  const FIRST_LETTER = new RegExp(/^[A-Z]/);

  const doc = documents.find(d => d.documentType === documentType);
  const reason = get(doc, 'documentNotes.instructionDescription', '');

  return reason
    .trim()
    .replace(FULL_STOP, '')
    .replace(FIRST_LETTER, l => l.toLowerCase());
};
