import {
  PARSE_ERROR,
  NETWORK_ERROR,
  translateStatusCode
} from './http-message';

export async function parseResponse(response) {
  let responseBody;

  try {
    responseBody = await response.json();
  } catch (exception) {
    return Promise.reject(PARSE_ERROR);
  }

  if (response.ok) {
    return responseBody;
  }

  const errorByStatusCode = translateStatusCode(response.status);

  if (errorByStatusCode.errors.length > 0) {
    return Promise.reject(errorByStatusCode);
  }

  const errorBody = {
    ...responseBody,
    code: response.status
  };

  return Promise.reject(errorBody);
}

export function handleError(error) {
  if (error instanceof TypeError) {
    return Promise.reject(NETWORK_ERROR);
  }

  return Promise.reject(error);
}
