import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) => ({
  list: {
    padding: 0
  },
  listIcon: {
    minWidth: 'auto',
    paddingRight: spacing(1)
  },
  listItem: {
    padding: 0
  }
}));

export const TEXTS = {
  TITLE: 'Transporte',
  SUBTITLE:
    'Você deverá utlizar o meio de transporte que está habilitado para condução.',
  FORM: {
    TRANSPORTTYPE: 'Tipo de transporte'
  }
};
