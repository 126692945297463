import { makeStyles } from '@material-ui/core/styles';
import ImageBackground from './images/profile_picture_background.png';

export const useStyles = makeStyles(({ spacing }) => ({
  image: {
    borderRadius: '50%',
    objectFit: 'cover'
  },
  profilePicture: {
    width: '290px',
    height: '290px'
  },
  example: {
    width: '140px',
    height: '133px'
  },
  box: {
    borderRadius: '50%',
    objectFit: 'cover',
    backgroundImage: `url(${ImageBackground})`,
    padding: '2px'
  },
  dialogWithoutPadding: {
    padding: spacing(0)
  }
}));

export const TEXTS = {
  INITIAL: {
    TITLE: 'Tire uma foto sua',
    SUBTITLE: 'Você precisa enviar sua foto para começar a fazer entregas.',
    EXAMPLE: 'Exemplo',
    INFO_TITLE: 'Saiba como tirar uma boa foto:',
    INFO_ITEMS: [
      'Não utilize capacete ou boné',
      'Tenha certeza que o foco está bom',
      'Use uma boa iluminação',
      'Tire uma foto do seu rosto, não de outra foto'
    ]
  },
  BLOCKED: {
    TITLE: 'Não conseguimos acessar a câmera',
    SUBTITLE_PERMISSION_DENIED:
      'Para prosseguir é necessário redefinir as permissões para autorizar o uso de câmera no site',
    SUBTITLE_DEVICE_NOT_FOUND:
      'Para prosseguir é necessário utilizar um equipamento que possua dispositivo de vídeo'
  },
  OK_GOT_IT: 'Ok, entendi',
  EDIT: {
    INFOS: [
      'Mantenha seu rosto na área marcada.',
      'Tire a foto sem capacete, boné e óculos escuros ou de grau, ok?'
    ]
  },
  REVIEW: {
    TITLE: 'A foto ficou boa?',
    SUBTITLE:
      'Esta é sua foto de identificação. Só envie se for possível ver seu rosto com clareza.'
  },
  SUCCESS: {
    TITLE: 'Sua foto foi aprovada',
    SUBTITLE: 'Ela serve como sua foto de identificação dentro do aplicativo.'
  },
  ERROR: {
    TITLE: 'Conserte sua foto de perfil',
    SUBTITLE:
      'Tente usar um fundo branco e sem tremer, beleza? Envie a foto sem boné, capacete ou óculos.'
  }
};
