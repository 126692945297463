import { makeStyles } from '@material-ui/core';

export const TEXT = {
  TITLE: 'Olá, <<NOME>>!',
  TITLE_EMPTY_NAME: 'Olá!',
  SUBTITLE: 'Sua conta foi criada com sucesso.',
  START_SEND_DOCUMENTS:
    'Agora, veja o que você precisa ter em mãos pra completar seu cadastro:',
  MEI_REQUIREMENT: 'MEI no seu nome com CNAE para fazer entregas.',
  CNH_REQUIREMENT: 'CNH definitiva e placa do carro, moto ou van que vai usar.',
  BANK_REQUIREMENT:
    'Conta bancária ativa em seu nome que não seja Conta Fácil.',
  CONTINUES: 'Continuar'
};

export const useStyles = makeStyles(() => ({
  contentTextMobile: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
}));
