import { Machine } from 'xstate';
import * as yup from 'yup';
import driverReferralMutation from 'infra/services/driver-referral';
import {
  emptyField,
  formValid,
  formInvalid,
  changeField,
  validateFields,
  initialContextFromFieldsValues,
  sendErrorNotification,
  sendSuccessNotification,
  FORM_STATE,
  FORM_ACTIONS
} from './utils/form';

export const initialContext = {
  fields: {
    referralCode: emptyField()
  },
  errors: []
};

export const FORM_ERRORS = {
  referralCode: 'Eita! Digite um código válido.'
};

const dgdSchema = yup.object().shape({
  referralCode: yup
    .string()
    .length(6, FORM_ERRORS.referralCode)
    .required(FORM_ERRORS.referralCode)
});

export const guards = {
  formValid,
  formInvalid
};

export const FORM_NOTIFICATIONS = {
  success: 'Beleza! Indicação confirmada.',
  error: 'Opa! Código inválido.'
};

export const actions = {
  changeField,
  validateFields: validateFields(dgdSchema),
  sendErrorNotification: sendErrorNotification(FORM_NOTIFICATIONS.error),
  sendSuccessNotification: sendSuccessNotification(FORM_NOTIFICATIONS.success)
};

export const services = {
  driverReferral: ({ fields }) =>
    driverReferralMutation({
      referralCode: fields.referralCode.value
    })
};

const driverGetDriverMachine = Machine(
  {
    id: 'driverGetDriver',
    initial: FORM_STATE.editing,
    context: initialContextFromFieldsValues(initialContext, dgdSchema),
    states: {
      [FORM_STATE.editing]: {
        initial: FORM_STATE.invalid,
        states: {
          [FORM_STATE.invalid]: {
            on: {
              '': [{ target: FORM_STATE.valid, cond: 'formValid' }]
            }
          },
          [FORM_STATE.valid]: {
            on: {
              '': [{ target: FORM_STATE.invalid, cond: 'formInvalid' }]
            }
          }
        },
        on: {
          [FORM_ACTIONS.submit]: {
            target: FORM_STATE.submitting,
            cond: 'formValid'
          },
          [FORM_ACTIONS.change]: {
            actions: ['changeField', 'validateFields']
          }
        }
      },
      [FORM_STATE.submitting]: {
        invoke: {
          id: 'driverReferral',
          src: 'driverReferral',
          onDone: {
            target: FORM_STATE.success,
            actions: 'sendSuccessNotification'
          },
          onError: {
            target: FORM_STATE.editing,
            actions: ['sendErrorNotification']
          }
        }
      },
      [FORM_STATE.success]: {
        type: 'final'
      }
    }
  },
  {
    guards,
    actions,
    services
  }
);

export default driverGetDriverMachine;
