import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) => ({
  dialogWithoutPadding: {
    padding: spacing(0)
  }
}));

export function getCamera() {
  return React.createRef();
}

export const TEXTS_FORM = {
  BLOCKED: {
    TITLE_PERMISSION_DENIED: 'Eita, você precisa liberar o acesso à câmera',
    TITLE_DEVICE_NOT_FOUND: 'Eita, este aparelho não tem câmera',
    SUBTITLE_PERMISSION_DENIED:
      'É só entrar nas permissões do seu navegador e permitir o uso da câmera no site.',
    SUBTITLE_DEVICE_NOT_FOUND:
      'Para tirar a foto, você precisa de um computador ou celular com câmera.',
    SUBTITLE_CNH:
      'É só entrar nas permissões do seu navegador e permitir o uso da câmera no site. Ou então você pode carregar uma foto da sua CNH.'
  },
  OK_GOT_IT: 'Ok, entendi'
};
