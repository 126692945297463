import React from 'react';
import PropTypes from 'prop-types';
import { DOCUMENT_TYPE, DOCUMENT_STATUS } from 'shared/constants/documents';
import { canEditDocument } from 'shared/utils/documents';
import { getCardStatus, getCardText } from '../utils';
import { DOCUMENT_CARD_EXPIRED } from '../constants';
import Card from './card';
import CardModal from './card-modal';

const DocumentCard = ({
  documentStatus,
  documentType,
  documentTitle,
  documentNotes,
  expiringIn,
  blockSubmission,
  helpInstruction,
  onClick
}) => {
  const cardText = getCardText({ documentStatus, documentType, expiringIn });
  const status = getCardStatus(documentStatus);

  const canEdit =
    !blockSubmission && canEditDocument(documentStatus, documentType);
  const errorMessage =
    {
      [DOCUMENT_STATUS.INVALID]: documentNotes.instructionDescription,
      [DOCUMENT_STATUS.EXPIRED]: DOCUMENT_CARD_EXPIRED
    }[documentStatus] || '';
  const onCardClick = () => canEdit && onClick();

  const defaultCard = () => (
    <Card
      status={status}
      text={helpInstruction || cardText}
      title={documentTitle}
      error={errorMessage}
      onClick={onCardClick}
      testId={documentType}
      disabled={!canEdit}
    />
  );

  const card =
    {
      [DOCUMENT_TYPE.MODAL]: () => (
        <CardModal
          text={cardText}
          error={errorMessage}
          onClick={onCardClick}
          testId={documentType}
          disabled={!canEdit}
        />
      )
    }[documentType] || defaultCard;

  return <>{card()}</>;
};

DocumentCard.propTypes = {
  documentStatus: PropTypes.string.isRequired,
  documentTitle: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  documentNotes: PropTypes.shape({
    invalidFields: PropTypes.arrayOf(PropTypes.string),
    instructionTitle: PropTypes.string,
    instructionDescription: PropTypes.string
  }),
  expiringIn: PropTypes.number,
  blockSubmission: PropTypes.bool,
  helpInstruction: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

DocumentCard.defaultProps = {
  documentNotes: {},
  expiringIn: null,
  blockSubmission: false,
  helpInstruction: ''
};

export default DocumentCard;
