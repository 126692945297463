import restRequest from 'infra/rest-request';

const DRIVER_MODAL_DOCUMENTS_URL = id => `api/v2/drivers/${id}/modal`;

export const putDriverModal = async (userId, data) => {
  const url = DRIVER_MODAL_DOCUMENTS_URL(userId);

  return restRequest()
    .url(url)
    .put(data)
    .text();
};

const DriverModal = {
  putDriverModal
};

export default DriverModal;
