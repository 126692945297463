export const DOCUMENT_STATUS = {
  PENDING: 'PENDING',
  WAITING_REVIEW: 'WAITING_REVIEW',
  VALID: 'VALID',
  INVALID: 'INVALID',
  EXPIRED: 'EXPIRED',
  ABOUT_TO_EXPIRE: 'ABOUT_TO_EXPIRE'
};

export const DOCUMENT_TYPE = {
  CNPJ: 'cnpj',
  CNH: 'cnh',
  LICENSE_PLATE: 'placa',
  BANK_ACCOUNT: 'bnk',
  PROFILE_PICTURE: 'picture',
  MODAL: 'transport_type'
};

export const DOCUMENT_TYPE_DISABLED = {
  ANTT: 'rntrc'
};
