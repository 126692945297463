import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { Check } from '@material-ui/icons';

import { colors } from '@loggi/mar';

import { ReactComponent as WarningIcon } from '../../atoms/illustrations/icons/warning.svg';

import { TEXT, LIST_OF_ACCEPTED_CNAES } from './constants';
import useStyles from './styles';

export default function CNPJMoreInfo({ open, setOpen }) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const getAcceptedCNAEWithDescriptionDisplay = cnae => {
    return (
      <Box key={cnae} pb={3} display="flex" alignItems="top">
        <Box pr={1}>
          <Check color="primary" fontSize="small" />
        </Box>
        <Typography variant="body1">
          <Box component="span" fontWeight="fontWeightBold">
            {cnae[0]}
          </Box>{' '}
          {cnae[1]}
        </Typography>
      </Box>
    );
  };

  const displayAcceptedCNAEWithDescriptions = () => {
    return LIST_OF_ACCEPTED_CNAES.map(getAcceptedCNAEWithDescriptionDisplay);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.cnaeDialog }}
      maxWidth="xl"
    >
      {open && (
        <Box px={2.5} pt={3.5} pb={2.5} color={colors.smoke[900]}>
          <Box pb={2} textAlign="center">
            <WarningIcon />
          </Box>
          <Box pb={4} display="flex" justifyContent="center">
            <Typography variant="h5">CNAEs aceitos</Typography>
          </Box>
          <Box pb={2.5}>{displayAcceptedCNAEWithDescriptions()}</Box>
          <Box flexGrow={1} display="flex" justifyContent="center">
            <Box className={classes.dialogButton}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={() => setOpen(false)}
              >
                {TEXT.OK_GOT_IT}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Dialog>
  );
}

CNPJMoreInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};
