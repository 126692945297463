import React from 'react';
import { Link } from '@material-ui/core';
import PAGES from 'routes/pages';
import { DOCUMENT_TYPE, DOCUMENT_STATUS } from 'shared/constants/documents';
import { DRIVER_STATUS } from 'shared/constants/driver';

export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.loggi.driverapp';

export const DOCUMENT_CARD_STATUS = {
  error: 'error',
  approved: 'approved',
  pending: 'pending',
  default: 'default'
};

export const DOCUMENT_PATH = documentType =>
  ({
    [DOCUMENT_TYPE.LICENSE_PLATE]: PAGES.LICENSE_PLATE.path,
    [DOCUMENT_TYPE.BANK_ACCOUNT]: PAGES.BANK_ACCOUNT.path,
    [DOCUMENT_TYPE.CNPJ]: PAGES.CNPJ.path,
    [DOCUMENT_TYPE.CNH]: PAGES.CNH.path,
    [DOCUMENT_TYPE.PROFILE_PICTURE]: PAGES.PROFILE_PICTURE.path,
    [DOCUMENT_TYPE.MODAL]: PAGES.DRIVER_MODAL.path
  }[documentType]);

export const TEXTS = {
  DOCUMENTS: {
    TITLE: 'Seus documentos',
    SUB_TITLE: 'Aqui você pode conferir todos os documentos do seu cadastro.'
  },
  STORE_CARD: {
    title: 'Baixe o aplicativo',
    text: 'Loggi para entregador'
  },
  [DRIVER_STATUS.IN_QUEUE]: {
    TITLE: 'tudo certo, agora é só aguardar! 🎉',
    SUB_TITLE: 'Assim que você puder começar a entregar, vamos te avisar.',
    DOCUMENT_HUB_CARD: {
      title: 'Seus documentos',
      error: 'Corrija e envie de novo',
      approved: 'Aprovados'
    },
    FAQ_TITLE: 'Enquanto você espera...',
    FAQ_ITEMS: [
      {
        question: '1) Posso trocar ou atualizar meus documentos?',
        answer: (
          <>
            Sim, mas só quando forem vencer. Entre no menu lateral, toque em{' '}
            <strong>cadastro</strong> e escolha o documento para alterar.
          </>
        )
      },
      {
        question: '2) Como recebo o repasse?',
        answer: (
          <>
            Você pode escolher uma das duas formas: semanal ou mensal. Confira
            as regras para cada uma delas
            <Link
              href="https://ajuda.loggi.com/hc/pt-br/articles/360017477032-Saiba-tudo-sobre-o-repasse-semanal-e-mensal"
              color="primary"
              target="_blank"
            >
              {' '}
              aqui.
            </Link>
          </>
        )
      },
      {
        question: '3) Em quanto tempo eu posso começar a entrega?',
        answer:
          'O prazo para você ser habilitado depende da demanda de pedidos. Assim que chegar sua vez, vamos te avisar!'
      }
    ]
  },
  [DRIVER_STATUS.LICENSED]: {
    TITLE: 'você já pode iniciar as entregas! 🎉',
    SUB_TITLE:
      'Vamos começar a entregar com a Loggi? É só baixar o aplicativo.',
    SUB_TITLE_APP: 'Vamos começar a entregar com a Loggi?',
    DOCUMENT_HUB_CARD: {
      title: 'Seus documentos',
      error: 'Corrija e envie de novo',
      approved: 'Aprovados'
    },
    BUTTON_START_DELIVERY: 'Começar a entregar',
    FAQ_TITLE: 'Passo a passo do seu primeiro dia',
    FAQ_ITEMS: [
      {
        question: '1) Como aceitar uma rota?',
        answer:
          'Seu app vai tocar com uma oferta e se você quiser fazer o trajeto, é só arrastar para a direita o botão com o coelho.'
      },
      {
        question: '2) Como se apresentar na hora da coleta ou entrega?',
        answer:
          'Quando chegar, identifique-se e informe que vai fazer uma coleta ou entrega. Na agência, vão te pedir o número da saca.'
      },
      {
        question: '3) Como conferir os pacotes?',
        answer:
          'Na agência, o time vai conferir com você. Tenha certeza que os pacotes estão lacrados e inteiros antes de entregar.'
      },
      {
        question: '4) Quanto tempo eu tenho para entregar os pacotes?',
        answer:
          'Todos os pacotes têm que ser entregues no mesmo dia e não podem atrasar. Se não for possível, devolva o pacote.'
      },
      {
        question: '5) O que fazer depois de finalizar a entrega?',
        answer:
          'Assim que você terminar, você vai ter o comprovante de repasse e já vai estar pronto para fazer outra entrega. 😄'
      }
    ]
  },
  LOGOUT: 'Sair'
};

export const PAGE_TITLE = state =>
  ({
    [DOCUMENT_STATUS.INVALID]: 'você precisa corrigir seus documentos.',
    [DOCUMENT_STATUS.WAITING_REVIEW]: 'seus documentos estão em análise.'
  }[state] || 'complete seu cadastro agora mesmo.');

export const ERROR_MESSAGE_SUBTITLE = errors =>
  errors && errors.length > 1
    ? 'Envie de novo os seguintes documentos:'
    : 'Envie de novo o seguinte documento:';

export const DOCUMENT_CARD_TEXT_DISABLED = 'Não pode ser enviado';
export const DOCUMENT_CARD_TEXT = (
  documentType,
  state,
  wordGender,
  expiringIn
) =>
  ({
    [DOCUMENT_STATUS.ABOUT_TO_EXPIRE]: `Vence em ${expiringIn} ${
      expiringIn > 1 ? 'dias' : 'dia'
    }`,
    [DOCUMENT_STATUS.WAITING_REVIEW]: 'Em análise',
    [DOCUMENT_STATUS.INVALID]: 'Enviar de novo',
    [DOCUMENT_STATUS.VALID]: `Aprovad${wordGender}`,
    [DOCUMENT_STATUS.PENDING]:
      DOCUMENT_TYPE.MODAL === documentType
        ? 'Sua CNH não permite que você faça entregas com o veículo informado.'
        : 'Enviar de novo'
  }[state] || 'Enviar');

export const DOCUMENT_CARD_ERROR_TITLE = 'Problema';
export const DOCUMENT_CARD_EXPIRED = 'Fora da validade';
export const DOCUMENT_HUB_ALERT_TITLE = 'Problema no seu Cadastro';
export const DOCUMENT_HUB_ALERT_MESSAGE =
  'Entre em contato com seu administrador';
