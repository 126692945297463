const CREDENTIAL_KEY = 'current_user';

const getCredentials = () => {
  const creadentials = localStorage.getItem(CREDENTIAL_KEY);
  return JSON.parse(creadentials) || {};
};

const storeCredentials = credential => {
  localStorage.setItem(CREDENTIAL_KEY, JSON.stringify(credential));
};

const removeCredentials = () => {
  localStorage.removeItem(CREDENTIAL_KEY);
};

const updateCredentials = ({ apiKey, email }) => {
  const credentials = getCredentials();

  credentials.apiKey = apiKey;
  credentials.email = email;

  localStorage.setItem(CREDENTIAL_KEY, JSON.stringify(credentials));
};

export {
  getCredentials,
  storeCredentials,
  removeCredentials,
  updateCredentials
};
