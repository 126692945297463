import graphqlRequest from 'infra/graphql-request/';

const availableTransportTypesQuery = `
  query AvailableTransportTypesQuery($driverId: Int) {
    availableTransportTypes (driverId: $driverId){
        id: typeId
        name: typeName
      }
  }
`;

export default function availableTransportTypes(driverId) {
  return graphqlRequest({
    query: availableTransportTypesQuery,
    variables: { driverId }
  }).then(({ data }) => data.availableTransportTypes);
}
