import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import Form from 'view/templates/base-form';
import Picture from '../components/picture';
import { TEXTS, FORM_TEST_ID } from '../constants';

const SuccessUploadForm = ({ context }) => {
  const { driver } = context;
  const { TITLE, SUBTITLE } = TEXTS.SUCCESS;

  return (
    <Form>
      <Form.Header title={TITLE} subtitle={SUBTITLE} />

      <Box
        data-testid={FORM_TEST_ID.SUCCESS}
        pb={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Picture picture={driver.cnh} />
      </Box>
    </Form>
  );
};

SuccessUploadForm.propTypes = {
  context: PropTypes.shape({
    driver: PropTypes.shape({
      cnh: PropTypes.string
    })
  }).isRequired
};

export default SuccessUploadForm;
