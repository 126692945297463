import restRequest from 'infra/rest-request';

const GET_DOCUMENTS_URL = id => `api/v2/drivers/${id}/documents`;
const SEND_DOCUMENT_URL = (id, documentType) =>
  `api/v2/drivers/${id}/documents/${documentType}`;

export const getDriverDocuments = userId => {
  const url = GET_DOCUMENTS_URL(userId);
  return restRequest()
    .url(url)
    .get()
    .json();
};

export const sendDriverDocument = (userId, documentType, data) => {
  const url = SEND_DOCUMENT_URL(userId, documentType);
  return restRequest()
    .errorType('json')
    .url(url)
    .post(data)
    .text();
};

const DriverDocuments = {
  getDriverDocuments,
  sendDriverDocument
};

export default DriverDocuments;
