import { Machine, assign } from 'xstate';
import * as yup from 'yup';
import { putDriverModal } from 'infra/services/driver-modal';
import availableTransportTypes from 'infra/services/available-transport-types';
import {
  emptyField,
  formValid,
  formInvalid,
  changeField,
  validateFields,
  initialContextFromFieldsValues,
  mergeValidationErrors,
  sendErrorNotification,
  sendSuccessNotification,
  FORM_STATE,
  FORM_ACTIONS
} from './utils/form';

export const initialContext = {
  transportTypes: [],
  fields: {
    transportType: emptyField()
  },
  errors: []
};

export const FORM_ERRORS = {
  transportType:
    'Opa, selecione um meio de transporte de acordo com sua categoria de habilitação.'
};

const FORM_NOTIFICATIONS = {
  success: 'Ótimo! O meio de transporte atualizado com sucesso.',
  error: 'Eita! O meio de transporte não foi atualizado, tente de novo.'
};

const driverModalSchema = yup.object().shape({
  transportType: yup.string().required(FORM_ERRORS.transportType)
});

const getInitialData = context => {
  return availableTransportTypes(context.driver.userId);
};
export const guards = {
  formValid,
  formInvalid
};

export const actions = {
  changeField,
  mergeValidationErrors,
  validateFields: validateFields(driverModalSchema),
  sendErrorNotification: sendErrorNotification(FORM_NOTIFICATIONS.error),
  sendSuccessNotification: sendSuccessNotification(FORM_NOTIFICATIONS.success)
};

const services = {
  getInitialData,
  updateDriverModal: async ({ driver, fields }) => {
    return putDriverModal(driver.userId, {
      transport_type: fields.transportType.value
    });
  }
};

const DriverModalMachine = Machine(
  {
    id: 'driverModal',
    initial: FORM_STATE.loading,
    context: initialContextFromFieldsValues(initialContext, driverModalSchema),
    states: {
      [FORM_STATE.loading]: {
        invoke: {
          id: 'getInitialData',
          src: 'getInitialData',
          onDone: {
            actions: [
              assign((_, { data }) => {
                const { fields } = initialContext;
                return {
                  transportTypes: data,
                  fields
                };
              })
            ],
            target: FORM_STATE.editing
          },
          onError: {
            actions: [
              assign(() => {
                const transportTypes = [];
                const { fields } = initialContext;
                return {
                  transportTypes,
                  fields
                };
              })
            ],
            target: FORM_STATE.failure
          }
        }
      },
      [FORM_STATE.editing]: {
        initial: FORM_STATE.invalid,
        states: {
          [FORM_STATE.invalid]: {
            on: {
              '': [{ target: FORM_STATE.valid, cond: 'formValid' }]
            }
          },
          [FORM_STATE.valid]: {
            on: {
              '': [{ target: FORM_STATE.invalid, cond: 'formInvalid' }]
            }
          }
        },
        on: {
          [FORM_ACTIONS.submit]: {
            target: FORM_STATE.submitting,
            cond: 'formValid'
          },
          [FORM_ACTIONS.change]: {
            actions: ['changeField', 'validateFields']
          }
        }
      },
      [FORM_STATE.submitting]: {
        invoke: {
          id: 'updateDriverModal',
          src: 'updateDriverModal',
          onDone: {
            target: FORM_STATE.success,
            actions: 'sendSuccessNotification'
          },
          onError: {
            target: FORM_STATE.editing,
            actions: ['mergeValidationErrors', 'sendErrorNotification']
          }
        }
      },
      [FORM_STATE.success]: {
        type: 'final'
      },
      [FORM_STATE.failure]: {
        on: {
          [FORM_ACTIONS.retry]: FORM_STATE.loading
        }
      }
    }
  },
  {
    guards,
    actions,
    services
  }
);

export default DriverModalMachine;
