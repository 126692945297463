import React from 'react';
import PropTypes from 'prop-types';

import { Box, makeStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';

export const useStyles = makeStyles(theme => ({
  blueIcon: {
    color: theme.palette.primary.main
  }
}));

export default function BlueCheckWithText({ texts, pb }) {
  const classes = useStyles();

  return (
    <Box>
      {texts.map(text => (
        <Box key={text} display="flex" alignItems="flex-start" pb={pb}>
          <Box component="span" pr={2}>
            <Check className={classes.blueIcon} />
          </Box>
          {text}
        </Box>
      ))}
    </Box>
  );
}

BlueCheckWithText.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.string).isRequired,
  pb: PropTypes.number.isRequired
};
