import get from 'lodash/get';
import graphqlRequest from '../graphql-request';

const requestLicensePlateData = `
  {
    viewer {
      driver {
        docLicensePlate {
            number
        }
      }
    }
  }
`;

export default function getLicensePlateData() {
  return graphqlRequest({
    query: requestLicensePlateData
  }).then(({ data, errors }) => {
    const { driver } = data.viewer;

    if (driver) {
      const licensePlate = get(driver, 'docLicensePlate', null);
      return get(licensePlate, 'number', '');
    }

    const errorResponse = { errors };
    return Promise.reject(errorResponse);
  });
}
