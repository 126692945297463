import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import DoneIcon from '@material-ui/icons/Done';
import useStyles from '../style';

const Info = ({ title, items, icon }) => {
  const styles = useStyles();
  const Icon = {
    done: () => <DoneIcon color="primary" />,
    close: () => <CloseIcon color="error" />
  }[icon];

  return (
    <Box py={2}>
      <Typography variant="subtitle2">
        <Box pb={1.5} fontWeight="fontWeightBold">
          {title}
        </Box>
      </Typography>

      <List className={styles.list}>
        {items.map(item => (
          <ListItem key={item} className={styles.listItem}>
            <ListItemIcon className={styles.listIcon}>
              <Icon />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body1">{item}</Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

Info.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  icon: PropTypes.oneOf(['done', 'close'])
};

Info.defaultProps = {
  icon: 'done'
};

export default Info;
