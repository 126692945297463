import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { TEXTS } from './constants';
import DocumentCardList from './componentes/document-card-list';

const DocumentHubWaitingTemplate = props => {
  const { documents, history } = props;
  const { TITLE, SUB_TITLE } = TEXTS.DOCUMENTS;

  return (
    <Box>
      <Box pt={4}>
        <Typography variant="h4">
          <Box>{TITLE}</Box>
        </Typography>
        <Box mt={2}>
          <Typography variant="subtitle1">
            <Box>{SUB_TITLE}</Box>
          </Typography>
        </Box>
      </Box>

      <DocumentCardList documents={documents} history={history} />
    </Box>
  );
};

DocumentHubWaitingTemplate.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      documentType: PropTypes.string,
      documentStatus: PropTypes.string,
      documentTitle: PropTypes.string,
      isPending: PropTypes.bool,
      expiringIn: PropTypes.number,
      documentNotes: PropTypes.shape({
        instructionDescription: PropTypes.string
      })
    })
  ),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

DocumentHubWaitingTemplate.defaultProps = {
  documents: []
};

export default DocumentHubWaitingTemplate;
