import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import featureSwitches from 'operations/config/feature-switches';
import Form from 'view/templates/base-form';
import Submit from 'view/templates/base-form/submit/picture-icon';
import CameraIsBlocked from 'view/templates/base-form/video-permission-denied';
import { TEXTS_FORM, getCamera } from 'view/templates/base-form/constant';
import { useStyles, TEXTS, FORM_TEST_ID } from '../constants';

const EditUploadForm = ({ onSubmit }) => {
  const [front, setFront] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const styles = useStyles();
  const canvas = React.createRef();
  const video = getCamera();
  const input = React.createRef();
  const enableStartCameraSync = useFeatureSwitch(
    featureSwitches.enableStartCameraSync
  );

  useEffect(() => {
    const stopCamera = () =>
      video &&
      video.current &&
      video.current.srcObject &&
      video.current.srcObject.getTracks().forEach(track => track.stop());

    const facingMode = front ? 'user' : 'environment';
    const setCamera = async () => {
      video.current.srcObject = await navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode
          }
        })
        .catch(() => setModalIsOpen(true));
    };

    const startCamera = () =>
      video && video.current ? setCamera() : setModalIsOpen(true);

    const startDeviceMedia = async () => {
      const stream = await navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode
          }
        })
        .catch(() => setModalIsOpen(true));

      if (video?.current) {
        video.current.srcObject = stream;
      } else {
        setModalIsOpen(true);
      }
    };

    stopCamera();

    if (enableStartCameraSync) {
      startDeviceMedia();
    } else {
      startCamera();
    }

    return () => stopCamera();
  }, [video, front, enableStartCameraSync]);

  const toggleCam = () => setFront(!front);
  const takePicture = () => {
    canvas.current.width = video.current.videoWidth;
    canvas.current.height = video.current.videoHeight;
    canvas.current.getContext('2d').drawImage(video.current, 0, 0);
    canvas.current.toBlob(onSubmit, 'image/png');
  };

  const handleChange = e => {
    const inputFile = e.target.files[0];

    onSubmit(inputFile);
  };

  const onClick = () => {
    input.current.click();
  };

  return (
    <>
      <Form>
        <Box
          data-testid={FORM_TEST_ID.EDIT}
          py={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography align="center" variant="subtitle2">
            <Box pb={4}>{TEXTS.EDIT.INFOS[0]}</Box>
          </Typography>

          <Box position="relative">
            <Box className={styles.videoOverlay} />
            <video className={styles.video} ref={video} muted autoPlay />
          </Box>
          <canvas ref={canvas} style={{ display: 'none' }} />

          <Typography align="center" variant="subtitle2">
            <Box pt={4}>{TEXTS.EDIT.INFOS[1]}</Box>
          </Typography>
        </Box>

        <input
          onChange={handleChange}
          ref={input}
          type="file"
          style={{ display: 'none' }}
          data-testid="upload-input"
          accept="image/png, image/jpeg"
        />

        <Submit onClick={takePicture} onToggleCam={toggleCam} />

        <Box
          onClick={onClick}
          className={styles.uploadLinkContent}
          pb={2}
          pt={4}
        >
          <Box pr={2} className={styles.uploadLink}>
            <CloudUploadIcon color="primary" />
          </Box>
          <Typography color="primary" align="center" variant="subtitle2">
            <Box className={styles.uploadLink}>{TEXTS.EDIT.UPLOAD_LINK}</Box>
          </Typography>
        </Box>
      </Form>
      {modalIsOpen && (
        <CameraIsBlocked
          modalIsOpen={modalIsOpen}
          title={TEXTS_FORM.BLOCKED.TITLE_PERMISSION_DENIED}
          subtitle={TEXTS_FORM.BLOCKED.SUBTITLE_CNH}
        />
      )}
    </>
  );
};

EditUploadForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default EditUploadForm;
