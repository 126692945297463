import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import useStyles from './style';
import { FormProvider } from './context';
import Input from './input';
import Header from './header';
import Submit from './submit';
import { fieldError, handleEvent } from './utils';

const Form = ({ onChange, onSubmit, fields, children }) => {
  const styles = useStyles();
  return (
    <FormProvider
      value={{
        handleChange: handleEvent({ onChange, touched: false, fields }),
        handleBlur: handleEvent({ onChange, touched: true, fields }),
        fieldError: fieldError(fields),
        fields
      }}
    >
      <Box component="form" className={styles.form} onSubmit={onSubmit}>
        {children}
      </Box>
    </FormProvider>
  );
};

Form.Header = Header;
Form.Input = Input;
Form.Submit = Submit;

Form.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  fields: PropTypes.shape({
    touched: PropTypes.bool,
    value: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string)
  }),
  children: PropTypes.node.isRequired
};

Form.defaultProps = {
  onChange: () => {},
  onSubmit: e => e.preventDefault(),
  fields: {}
};

export default Form;
