import { Machine, assign, spawn } from 'xstate';
import initializationMachine from 'machines/initialization.machine';
import PAGES from 'routes/pages';
import pageMachineConfig from './utils/pages-machine';

const { pagesStates, pagesActions, pagesEvents } = pageMachineConfig(PAGES);

export const initialContext = {
  driver: {
    firstName: null,
    userId: null,
    transportType: null,
    profilePicture: null,
    photoUrl: null,
    cnh: null,
    name: null,
    cpf: null,
    email: null,
    mobileNumber: null,
    citySlug: null,
    terms: null,
    dgd: null,
    emailIsVerified: null
  },
  isAuthenticated: false,
  documents: null,
  notification: null,
  services: {
    initializationMachine: null
  }
};

export const actions = {
  ...pagesActions,
  setUser: assign({
    driver: (_, { data }) => ({
      userId: data.userId,
      firstName: data.firstName,
      transportType: data.transportType,
      photoUrl: data.photoUrl,
      name: data.name,
      cpf: data.cpf,
      email: data.email,
      mobileNumber: data.mobileNumber,
      citySlug: data.citySlug,
      terms: data.terms,
      dgd: data.dgd,
      emailIsVerified: data.emailIsVerified
    })
  }),
  setDriverEmailIsVerified: assign({
    driver: context => ({
      ...context.driver,
      emailIsVerified: true
    })
  }),
  setIsAuthenticated: assign({
    isAuthenticated: () => true
  }),
  setIsNotAuthenticated: assign({
    isAuthenticated: false
  }),
  setDocuments: assign({
    documents: (_, { data }) => data.documents
  }),
  loading: assign({
    services: context => ({
      ...context.services,
      initializationMachine: spawn(
        initializationMachine.withContext({
          ...initializationMachine.context,
          ...context
        })
      )
    })
  }),
  setNotification: assign({
    notification: (_, event) => ({
      color: event.color,
      message: event.message,
      startAdornment: event.startAdornment
    })
  }),
  closeNotification: assign({
    notification: () => null
  })
};

const orchestratorMachine = Machine(
  {
    id: 'odin, the allfather of machines',
    context: initialContext,
    type: 'parallel',
    states: {
      app: {
        initial: 'loading',
        on: {
          ...pagesEvents,
          AUTHORIZED: {
            actions: 'setIsAuthenticated',
            target: '.idle'
          },
          SET_DOCUMENTS: {
            actions: 'setDocuments'
          },
          UNAUTHORIZED: {
            actions: 'setIsNotAuthenticated',
            target: '.idle'
          },
          SETUSER: {
            actions: 'setUser'
          },
          SET_LOADING: {
            target: '.loading'
          },
          SETEMAILISVERIFIED: {
            actions: 'setDriverEmailIsVerified'
          }
        },
        states: {
          idle: {},
          loading: {
            entry: 'loading'
          },
          ...pagesStates
        }
      },
      notification: {
        initial: 'closed',
        states: {
          open: {
            on: {
              'NOTIFICATION.CLOSE': {
                target: 'closed',
                actions: ['closeNotification']
              }
            }
          },
          closed: {
            on: {
              'NOTIFICATION.SET': {
                target: 'open',
                actions: ['setNotification']
              }
            }
          }
        }
      }
    }
  },
  {
    actions
  }
);

export default orchestratorMachine;
