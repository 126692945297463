import { makeStyles } from '@material-ui/core';

export const TEXT = {
  TITLE: 'No momento você não é elegível para entregas.',
  SUBTITLE:
    'Seus documentos foram processados e, de acordo com nosso <a href="https://www.loggi.com/termos-de-uso-entregadores/">Termos de uso e Condições</a>, nesse momento você não atende os critérios para fazer entregas com o nosso aplicativo.'
};

export const useStyles = makeStyles(theme => ({
  containerPage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  blueIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}));
