import { makeStyles } from '@material-ui/core/styles';
import TopBackground from './images/cnh_background_top.png';
import MiddleBackground from './images/cnh_background_middle.png';
import BottomBackground from './images/cnh_background_bottom.png';

export const FORM_TEST_ID = {
  INITIAL: 'initial-form',
  EDIT: 'edit-form',
  REVIEW: 'review-form',
  ERROR: 'error-form',
  SUCCESS: 'success-form',
  LOADING: 'loading'
};

export const useStyles = makeStyles(() => ({
  example: {
    width: '160px',
    height: '205px'
  },
  video: {
    objectFit: 'cover',
    width: '290px',
    height: '290px'
  },
  videoOverlay: {
    width: '290px',
    height: '290px',
    backgroundImage: `
      url(${TopBackground}),
      url(${MiddleBackground}),
      url(${BottomBackground})
    `,
    backgroundPosition: 'top, 50%, bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'absolute'
  },
  image: {
    objectFit: 'contain',
    width: '290px',
    height: '290px'
  },
  uploadLinkContent: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  uploadLink: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));

export const TEXTS = {
  INITIAL: {
    TITLE: 'Tire uma foto da sua CNH',
    SUBTITLE:
      'Atenção: Seu documento deve estar dentro da validade e ser definitivo.',
    EXAMPLE: 'Exemplo',
    INFO_TITLE: 'Como tirar a foto da sua CNH:',
    INFO_ITEMS: ['Abrir a CNH na hora da foto', 'Garantir uma iluminação clara']
  },
  EDIT: {
    INFOS: ['Frente', 'Verso'],
    UPLOAD_LINK: 'Fazer upload de imagem'
  },
  REVIEW: {
    TITLE: 'A foto ficou boa?',
    SUBTITLE:
      'Dê uma conferida para ver se a foto está legível e com foco, isso ajuda na hora da aprovação.'
  },
  SUCCESS: {
    TITLE: 'Sua CNH está aprovada',
    SUBTITLE:
      'Esse é o documento que você vai usar para se identificar, caso precise.'
  },
  ERROR: reason => ({
    TITLE: 'Corrija sua CNH',
    SUBTITLE: [
      'Sua CNH foi rejeitada',
      reason ? ` porque ${reason}` : '',
      '. Envie uma foto válida pra gente.'
    ].join('')
  })
};
