const AGENCY_REGEX = new RegExp(/^[0-9]{4}(-[xX0-9])?$/);
const ACCOUNT_REGEX = new RegExp(/^[0-9]{2,18}-[xX0-9]$/);

export const isAgencyValid = value => AGENCY_REGEX.test(value);
export const isAccountValid = value => ACCOUNT_REGEX.test(value);

export default {
  isAgencyValid,
  isAccountValid
};
