import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { getDriverStatus, formatErrorMessage } from './utils';
import {
  PAGE_TITLE,
  ERROR_MESSAGE_SUBTITLE,
  DOCUMENT_HUB_ALERT_TITLE,
  DOCUMENT_HUB_ALERT_MESSAGE
} from './constants';
import DocumentCardList from './componentes/document-card-list';
import AlertCard from './componentes/alert-card';

const IncompleteTemplate = ({ name, documents, history, hasAlert }) => {
  const { status, errors } = getDriverStatus(documents);
  const title = PAGE_TITLE(status);

  return (
    <Box>
      <Box pt={4}>
        <Typography variant="h4">
          <Box fontWeight="fontWeightBold">{name},</Box>
          <Box>{title}</Box>
        </Typography>
        {hasAlert && (
          <AlertCard
            testId="alertCard"
            title={DOCUMENT_HUB_ALERT_TITLE}
            text={DOCUMENT_HUB_ALERT_MESSAGE}
          />
        )}
        {errors.length > 0 && (
          <Box mt={2}>
            <Typography variant="subtitle1">
              <Box>{ERROR_MESSAGE_SUBTITLE(errors)}</Box>
              <Box fontWeight="fontWeightBold">
                {formatErrorMessage(errors)}
              </Box>
            </Typography>
          </Box>
        )}
      </Box>

      <DocumentCardList documents={documents} history={history} />
    </Box>
  );
};

IncompleteTemplate.propTypes = {
  name: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      documentType: PropTypes.string,
      documentStatus: PropTypes.string,
      documentTitle: PropTypes.string,
      isPending: PropTypes.bool,
      expiringIn: PropTypes.number,
      documentNotes: PropTypes.shape({
        instructionDescription: PropTypes.string
      }),
      blockSubmission: PropTypes.bool,
      helpInstruction: PropTypes.string
    })
  ),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  hasAlert: PropTypes.bool
};

IncompleteTemplate.defaultProps = {
  documents: [],
  hasAlert: false
};

export default IncompleteTemplate;
