import React from 'react';
import PropTypes from 'prop-types';

import ErrorCriticalTemplate from 'view/templates/error-critical/index';

export default function ErrorCriticalPage({ firstName }) {
  return <ErrorCriticalTemplate firstName={firstName} />;
}

ErrorCriticalPage.propTypes = {
  firstName: PropTypes.string.isRequired
};
