import get from 'lodash/get';
import graphqlRequest from '../graphql-request';

const requestGetDriverCnh = `
  {
    viewer {
      driver {
        cnh {
          fileUrl
        }
      }
    }
  }
`;

export default function getDriverCnh() {
  return graphqlRequest({
    query: requestGetDriverCnh
  }).then(({ data, errors }) => {
    const { driver } = data.viewer;

    if (driver) {
      return {
        cnh: get(driver, 'cnh.fileUrl', null)
      };
    }

    const errorResponse = { errors };
    return Promise.reject(errorResponse);
  });
}
