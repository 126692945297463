import React from 'react';
import PropTypes from 'prop-types';
import { useService } from '@xstate/react';
import { Interpreter } from 'xstate';
import { FORM_ACTIONS, FORM_STATE } from 'machines/utils/form';
import PAGES from 'routes/pages';

import SignupLayoutTemplate from 'view/templates/signup-layout';
import DriverGetDriverComponent from './driver-get-driver.component';

const DriverGetDriverPage = ({ context, history, isDesktop }) => {
  const { services } = context;
  const { DriverGetDriverMachine } = services;
  const [current, send] = useService(DriverGetDriverMachine);

  const submitting = current.matches(FORM_STATE.submitting);
  const invalid = current.matches(
    `${FORM_STATE.editing}.${FORM_STATE.invalid}`
  );
  const disableSubmit = invalid || submitting;

  if (current.matches(FORM_STATE.success)) {
    history.push(PAGES.EMAIL_VERIFICATION.path);
    return <></>;
  }

  const handleChange = data => send(FORM_ACTIONS.change, data);
  const handleSkip = () => history.push(PAGES.EMAIL_VERIFICATION.path);
  const handleSubmit = event => {
    event.preventDefault();
    send(FORM_ACTIONS.submit);
  };

  const page = (
    <DriverGetDriverComponent
      onChange={handleChange}
      onSubmit={handleSubmit}
      onSkip={handleSkip}
      context={current.context}
      disableSubmit={disableSubmit}
    />
  );

  return <SignupLayoutTemplate isDesktop={isDesktop} page={page} />;
};

DriverGetDriverPage.propTypes = {
  context: PropTypes.shape({
    services: PropTypes.shape({
      DriverGetDriverMachine: PropTypes.instanceOf(Interpreter)
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  isDesktop: PropTypes.bool.isRequired
};

export default DriverGetDriverPage;
