import React from 'react';
import PropTypes from 'prop-types';
import { FORM_STATE } from 'machines/utils/form';
import InitialUploadForm from './forms/initial';
import EditUploadForm from './forms/edit';
import ReviewUploadForm from './forms/review';
import ErrorUploadForm from './forms/error';
import SuccessUploadForm from './forms/success';
import LoadingTemplate from '../loading';

const CnhFormTemplate = ({
  state,
  context,
  goToEdit,
  onTakePicture,
  onClear,
  onSubmit
}) => {
  const Template = {
    [FORM_STATE.idle]: () => (
      <InitialUploadForm data-testid="initial" onSubmit={goToEdit} />
    ),
    [`${FORM_STATE.editing}.${FORM_STATE.invalid}`]: () => (
      <EditUploadForm data-testid="editing-invalid" onSubmit={onTakePicture} />
    ),
    [`${FORM_STATE.editing}.${FORM_STATE.valid}`]: () => (
      <ReviewUploadForm
        data-testid="editing-valid"
        context={context}
        onCancel={onClear}
        onSubmit={onSubmit}
      />
    ),
    [`${FORM_STATE.editing}.${FORM_STATE.loadingToken}`]: () => (
      <LoadingTemplate />
    ),
    [FORM_STATE.submitting]: () => <LoadingTemplate />,
    [FORM_STATE.documentInvalid]: () => (
      <ErrorUploadForm
        data-testid="error"
        context={context}
        onSubmit={goToEdit}
      />
    ),
    [FORM_STATE.documentValid]: () => (
      <SuccessUploadForm data-testid="success" context={context} />
    )
  }[state];

  return <Template />;
};

CnhFormTemplate.propTypes = {
  context: PropTypes.shape({}).isRequired,
  state: PropTypes.string.isRequired,
  goToEdit: PropTypes.func,
  onTakePicture: PropTypes.func,
  onClear: PropTypes.func,
  onSubmit: PropTypes.func
};

CnhFormTemplate.defaultProps = {
  goToEdit: () => {},
  onTakePicture: () => {},
  onClear: () => {},
  onSubmit: () => {}
};

export default CnhFormTemplate;
