import { makeStyles } from '@material-ui/core/styles';
import { DOCUMENT_STATUS, DOCUMENT_TYPE } from 'shared/constants/documents';
import { isDocumentDisabled } from 'shared/utils/documents';
import {
  DOCUMENT_CARD_STATUS,
  DOCUMENT_CARD_TEXT,
  DOCUMENT_CARD_TEXT_DISABLED
} from './constants';

export const getDriverStatus = documents =>
  Array.isArray(documents) &&
  documents.reduce(
    (acc, value) => {
      if (
        [acc.status, value.documentStatus].includes(DOCUMENT_STATUS.INVALID)
      ) {
        acc.status = DOCUMENT_STATUS.INVALID;
        if (value.documentStatus === DOCUMENT_STATUS.INVALID)
          acc.errors.push(value.documentTitle);

        return acc;
      }
      if (
        [acc.status, value.documentStatus].includes(
          DOCUMENT_STATUS.WAITING_REVIEW
        )
      ) {
        acc.status = DOCUMENT_STATUS.WAITING_REVIEW;
        return acc;
      }
      return acc;
    },
    { errors: [], status: null }
  );

export const formatErrorMessage = errors =>
  errors.length > 1
    ? `${errors.slice(0, -1).join(', ')} e ${errors.slice(-1)}`
    : errors[0];

export const getCardText = ({ documentStatus, documentType, expiringIn }) => {
  const gender = documentType === DOCUMENT_TYPE.CNPJ ? 'o' : 'a';

  return isDocumentDisabled(documentType)
    ? DOCUMENT_CARD_TEXT_DISABLED
    : DOCUMENT_CARD_TEXT(documentType, documentStatus, gender, expiringIn);
};

export const getCardClass = status =>
  DOCUMENT_CARD_STATUS.error === status ? 'documentCardError' : 'documentCard';

export const getBulletClass = state =>
  ({
    [DOCUMENT_CARD_STATUS.error]: 'bulletError',
    [DOCUMENT_CARD_STATUS.approved]: 'bulletApproved',
    [DOCUMENT_CARD_STATUS.pending]: 'bulletPending'
  }[state] || 'bulletDefault');

export const getCardStatus = state =>
  ({
    [DOCUMENT_STATUS.INVALID]: DOCUMENT_CARD_STATUS.error,
    [DOCUMENT_STATUS.EXPIRED]: DOCUMENT_CARD_STATUS.error,
    [DOCUMENT_STATUS.VALID]: DOCUMENT_CARD_STATUS.approved,
    [DOCUMENT_STATUS.ABOUT_TO_EXPIRE]: DOCUMENT_CARD_STATUS.pending,
    [DOCUMENT_STATUS.WAITING_REVIEW]: DOCUMENT_CARD_STATUS.pending
  }[state] || DOCUMENT_CARD_STATUS.default);

export const useStyles = makeStyles(theme => ({
  bulletError: {
    backgroundColor: theme.palette.error.main
  },
  bulletApproved: {
    backgroundColor: theme.palette.success.main
  },
  bulletPending: {
    backgroundColor: theme.palette.warning.main
  },
  bulletDefault: {
    backgroundColor: theme.palette.info.main
  },
  documentCardLink: {
    cursor: 'pointer'
  },
  documentCard: {
    backgroundColor: theme.palette.background.default
  },
  documentCardError: {
    backgroundColor: theme.palette.error.contrastText
  },
  documentCardErrorMessage: {
    color: theme.palette.error.main
  },
  profilePicture: {
    width: '80px',
    height: '80px',
    objectFit: 'cover',
    borderRadius: '50%'
  }
}));
