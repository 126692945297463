import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

const routeWithRedirect = ({ redirectIf, redirectTo }) => {
  const RedirectRoute = ({ children, component: Component, path, exact }) => (
    <Route
      path={path}
      exact={exact}
      render={({ location }) =>
        redirectIf ? (
          <Redirect
            to={{
              pathname: redirectTo,
              state: { from: location }
            }}
          />
        ) : (
          children || <Component />
        )
      }
    />
  );
  RedirectRoute.propTypes = {
    children: PropTypes.node,
    component: PropTypes.node,
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool
  };
  RedirectRoute.defaultProps = {
    children: null,
    component: null,
    exact: false
  };
  return RedirectRoute;
};

export default routeWithRedirect;
