import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import useStyles from '../style';

export const TEXTS = {
  SUBMIT: 'Enviar esta foto',
  CLEAR: 'Tirar outra'
};

const Submit = ({ onClear, onClick }) => {
  const styles = useStyles();
  return (
    <Box
      className={styles.submitButton}
      display="flex"
      justifyContent="space-between"
    >
      <Box width="100%" pr={1}>
        <Button
          onClick={onClear}
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
        >
          {TEXTS.CLEAR}
        </Button>
      </Box>
      <Box width="100%" pl={1}>
        <Button
          onClick={onClick}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          {TEXTS.SUBMIT}
        </Button>
      </Box>
    </Box>
  );
};

Submit.propTypes = {
  onClear: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Submit;
