import graphqlRequest from 'infra/graphql-request';

const driverReferralMutation = `
  mutation DriverReferralMutation($referralCode: String!) {
    driverReferralMutation(input: {
        referralCode: $referralCode
    }) {
        isSuccessfully: success
        errors {
            message
        }
    }
  }
`;

export default function driverReferral(variables) {
  return graphqlRequest({
    query: driverReferralMutation,
    variables
  }).then(({ data }) => {
    const response = data.driverReferralMutation;

    if (response && response.isSuccessfully) {
      return response;
    }

    const errorResponse = { errors: response.errors };
    return Promise.reject(errorResponse);
  });
}
