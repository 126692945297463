import get from 'lodash/get';
import graphqlRequest from '../graphql-request';

const requestGetDriver = `
  {
    viewer {
      driver {
        pk
        firstName
        transportType
        photoUrl {
          profileHighRes
        }
        email
        emailIsVerified
      }
    }
  }
`;

export default function getDriver() {
  return graphqlRequest({
    query: requestGetDriver
  }).then(({ data, errors }) => {
    const { driver } = data.viewer;

    if (driver) {
      return {
        firstName: driver.firstName,
        transportType: driver.transportType,
        photoUrl: get(driver, 'photoUrl.profileHighRes', null),
        userId: driver.pk,
        email: driver.email,
        emailIsVerified: driver.emailIsVerified
      };
    }

    const errorResponse = { errors };
    return Promise.reject(errorResponse);
  });
}
