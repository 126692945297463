export const handleEvent = ({ onChange, touched }) => event => {
  const { target } = event;
  const { name, type, value, checked } = target;
  onChange({
    name,
    value: type === 'checkbox' ? checked : value,
    touched
  });
};

export const fieldError = fields => fieldName => {
  const field = fields ? fields[fieldName] : {};

  if (field.touched && field.errors[0]) {
    return field.errors[0];
  }

  return '';
};
