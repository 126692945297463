import React from 'react';
import { Interpreter } from 'xstate';
import PropTypes from 'prop-types';
import { useService } from '@xstate/react';
import LoadingTemplate from 'view/templates/loading';
import LoadingErrorTemplate from 'view/templates/loading-error';
import BankAccountFormTemplate from 'view/templates/bank-account-form';
import SignupLayoutTemplate from 'view/templates/signup-layout';
import PAGES from 'routes/pages';
import { FORM_ACTIONS, FORM_STATE } from 'machines/utils/form';
import { isDocumentEditable, isMeiValid } from 'shared/utils/documents';
import { DOCUMENT_TYPE } from 'shared/constants/documents';

const BankAccountForm = ({ context, history, isDesktop }) => {
  const { services } = context;
  const { BankAccountMachine } = services;
  const [current, send] = useService(BankAccountMachine);
  const { documents } = current.context;

  const canEditDocument = isDocumentEditable(
    documents,
    DOCUMENT_TYPE.BANK_ACCOUNT
  );
  const invalid = current.matches(
    `${FORM_STATE.editing}.${FORM_STATE.invalid}`
  );
  const submitting = current.matches(FORM_STATE.submitting);
  const disableSubmit = invalid || submitting;

  const goBack = () => history.push(PAGES.DOCUMENT_HUB.path);
  const retry = () => send(FORM_ACTIONS.retry);

  if (current.matches(FORM_STATE.success) || !canEditDocument) {
    goBack();
  }

  const handleChange = data => send(FORM_ACTIONS.change, data);
  const handleSubmit = event => {
    event.preventDefault();
    send(FORM_ACTIONS.submit);
  };

  const defaultPage = () => (
    <BankAccountFormTemplate
      onChange={handleChange}
      onSubmit={handleSubmit}
      context={current.context}
      disableSubmit={disableSubmit}
      isMeiApproved={isMeiValid(current.context)}
    />
  );

  const page =
    {
      [FORM_STATE.loading]: () => <LoadingTemplate />,
      [FORM_STATE.failure]: () => <LoadingErrorTemplate onRetry={retry} />
    }[current.value] || defaultPage;

  return (
    <SignupLayoutTemplate isDesktop={isDesktop} page={page()} onBack={goBack} />
  );
};

BankAccountForm.propTypes = {
  context: PropTypes.shape({
    services: PropTypes.shape({
      BankAccountMachine: PropTypes.instanceOf(Interpreter)
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  isDesktop: PropTypes.bool
};

BankAccountForm.defaultProps = {
  history: {},
  isDesktop: false
};

export default BankAccountForm;
