import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';

import { WAITING_STATUS } from 'shared/constants/driver';
import { isEmbededDriverApp } from 'shared/utils/driver-app';
import IncompleteTemplate from './incomplete';
import WaitingTemplate from './complete';
import { TEXTS } from './constants';

function LogoutButton({ logout }) {
  return (
    <Box pt={2} pb={2.5}>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        data-testid="btn-cognito-logout"
        fullWidth
        onClick={logout}
      >
        {TEXTS.LOGOUT}
      </Button>
    </Box>
  );
}

LogoutButton.propTypes = {
  logout: PropTypes.func.isRequired
};

const DocumentHubTemplate = props => {
  const {
    name,
    documents,
    status,
    history,
    profilePicture,
    logout,
    hasAlert
  } = props;

  if (WAITING_STATUS.includes(status)) {
    return (
      <Box>
        <WaitingTemplate
          profilePicture={profilePicture}
          name={name}
          documents={documents}
          history={history}
          status={status}
        />
        {!isEmbededDriverApp() && <LogoutButton logout={logout} />}
      </Box>
    );
  }

  return (
    <Box>
      <IncompleteTemplate
        name={name}
        documents={documents}
        history={history}
        hasAlert={hasAlert}
      />
      {!isEmbededDriverApp() && <LogoutButton logout={logout} />}
    </Box>
  );
};

DocumentHubTemplate.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  profilePicture: PropTypes.string,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      documentType: PropTypes.string,
      documentStatus: PropTypes.string,
      documentTitle: PropTypes.string,
      isPending: PropTypes.bool,
      expiringIn: PropTypes.number,
      documentNotes: PropTypes.shape({
        instructionDescription: PropTypes.string
      }),
      blockSubmission: PropTypes.bool,
      helpInstruction: PropTypes.string
    })
  ),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  logout: PropTypes.func.isRequired,
  hasAlert: PropTypes.bool
};

DocumentHubTemplate.defaultProps = {
  documents: [],
  profilePicture: null,
  hasAlert: false
};

export default DocumentHubTemplate;
