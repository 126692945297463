import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider, Button } from '@material-ui/core';
import { DOCUMENT_STATUS } from 'shared/constants/documents';
import { isEmbededDriverApp } from 'shared/utils/driver-app';
import PAGES from 'routes/pages';
import { TEXTS, DOCUMENT_CARD_STATUS, PLAY_STORE_URL } from './constants';
import Card from './componentes/card';
import Picture from './componentes/picture';

const WaitingTemplate = ({
  name,
  status,
  profilePicture,
  history,
  documents
}) => {
  const {
    TITLE,
    SUB_TITLE,
    BUTTON_START_DELIVERY,
    FAQ_TITLE,
    FAQ_ITEMS,
    DOCUMENT_HUB_CARD,
    SUB_TITLE_APP
  } = TEXTS[status];
  const onDocCardClick = () => history.push(PAGES.DOCUMENT_HUB_WAITING.path);
  const onStoreCardClick = () => {
    window.open(PLAY_STORE_URL, '_blank');
  };
  const onStartDeliveryClick = () => history.goBack();

  const hasError = documents.some(
    ({ documentStatus }) => documentStatus === DOCUMENT_STATUS.INVALID
  );

  const cardProps = DOCUMENT_HUB_CARD && {
    title: DOCUMENT_HUB_CARD.title,
    status: hasError
      ? DOCUMENT_CARD_STATUS.error
      : DOCUMENT_CARD_STATUS.approved,
    text: hasError ? DOCUMENT_HUB_CARD.error : DOCUMENT_HUB_CARD.approved
  };

  const isEmbeded = isEmbededDriverApp();

  const getDisplayState = (isembeded, driverStatus) => {
    if (!isembeded && driverStatus === 'habilitado') {
      return (
        <Box>
          <Card
            key="app"
            testId={TEXTS.STORE_CARD.title}
            title={TEXTS.STORE_CARD.title}
            text={TEXTS.STORE_CARD.text}
            withIcon
            onClick={onStoreCardClick}
          />
        </Box>
      );
    }

    if (isembeded && driverStatus === 'habilitado') {
      return (
        <Box pt={1.5}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={onStartDeliveryClick}
          >
            {BUTTON_START_DELIVERY}
          </Button>
        </Box>
      );
    }

    if (driverStatus === 'em_fila') {
      return <></>;
    }

    return <></>;
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" pt={4}>
        <Box>
          <Typography variant="h4">
            <Box fontWeight="fontWeightBold">{name},</Box>
            <Box>{TITLE}</Box>
          </Typography>
        </Box>
        <Box ml={2} display="flex" alignItems="center">
          <Picture picture={profilePicture} />
        </Box>
      </Box>

      <Box mt={2}>
        <Typography variant="subtitle1">
          <Box>{status === 'habilitado' ? SUB_TITLE_APP : SUB_TITLE}</Box>
        </Typography>
      </Box>

      <Box my={3}>
        <>
          <Card
            testId={cardProps.title}
            key="documentos"
            title={cardProps.title}
            status={cardProps.status}
            text={cardProps.text}
            onClick={onDocCardClick}
          />
          {getDisplayState(isEmbeded, status)}
        </>
      </Box>

      <Divider />

      <Box py={3}>
        <Typography variant="h4">
          <Box fontWeight="fontWeightBold">{FAQ_TITLE}</Box>
        </Typography>

        {FAQ_ITEMS.map(({ question, answer }) => (
          <Box key={question} mt={4}>
            <Typography variant="subtitle1">
              <Box fontWeight="fontWeightBold">{question}</Box>
              <Box mt={1}>{answer}</Box>
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

WaitingTemplate.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  profilePicture: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      documentType: PropTypes.string,
      documentStatus: PropTypes.string,
      documentTitle: PropTypes.string,
      isPending: PropTypes.bool,
      expiringIn: PropTypes.number,
      documentNotes: PropTypes.shape({
        instructionDescription: PropTypes.string
      }),
      blockSubmission: PropTypes.bool,
      helpInstruction: PropTypes.string
    })
  ).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }).isRequired
};

export default WaitingTemplate;
