import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Snackbar } from '@material-ui/core';
import { colors } from '@loggi/mar';

const autoHideDuration = 5000; // is defined in miliseconds

function Alert({ startAdornment, message, open, onClose, color }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      ClickAwayListenerProps={{
        onClickAway: () => {} // disable default behavior of call onClose on click away
      }}
    >
      <Box
        onClick={onClose}
        bgcolor={colors.root[0]}
        p={2.5}
        m={1.5}
        borderRadius={8}
        boxShadow={13}
        width="100%"
        display="flex"
        alignItems="center"
        color={color}
      >
        <Box pr={2.5}>{startAdornment}</Box>

        <Typography variant="body1">{message}</Typography>
      </Box>
    </Snackbar>
  );
}

Alert.propTypes = {
  startAdornment: PropTypes.element.isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  color: PropTypes.string
};

Alert.defaultProps = {
  color: colors.smoke[900]
};

export default React.memo(Alert);
