import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';

import { DOCUMENT_TYPE } from 'shared/constants/documents';
import CNPJMoreInfo from './cnpj-more-info';
import { TEXT, LIST_OF_ACCEPTED_CNAES } from './constants';
import useStyles from './styles';
import Form from '../base-form';

const CnpjFormTemplate = ({ onChange, onSubmit, context, disableSubmit }) => {
  const { fields, documents } = context;
  const cnpj = documents
    ? documents.filter(doc => doc.documentType === DOCUMENT_TYPE.CNPJ)[0]
    : null;
  const invalidCnpj = cnpj ? cnpj.documentStatus === TEXT.INVALID : true;

  const getRejectionDescription = text => {
    if (text && text.length > 1) {
      const description = text.charAt(0).toLowerCase() + text.slice(1);
      return description.slice(-1) === '.'
        ? description.concat(' ')
        : description.concat('. ');
    }

    return text;
  };

  const getSubtitleWhenCnpjIsInvalid = () => {
    if (invalidCnpj) {
      if (cnpj && cnpj.documentNotes) {
        const { instructionDescription } = cnpj.documentNotes;
        if (instructionDescription) {
          return TEXT.INVALID_CNPJ_SUBTITLE.concat(
            getRejectionDescription(instructionDescription)
          ).concat(TEXT.INVALID_CNPJ_TEXT);
        }
      }
      return TEXT.INVALID_CNPJ_SUBTITLE_NO_REASON.concat(
        TEXT.INVALID_CNPJ_TEXT
      );
    }

    return TEXT.SUBTITLE;
  };

  const inputList = [
    {
      name: 'cnpj',
      placeholder: TEXT.FORM.CNPJ,
      inputProps: { maxLength: 18 }
    }
  ];
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const getAcceptedCNAEDisplay = cnae => {
    return (
      <Box key={cnae} pb={2.0} display="flex" alignItems="top">
        <Box pr={1}>
          <Check color="primary" fontSize="small" />
        </Box>
        <Typography variant="body1">{cnae[0]}</Typography>
      </Box>
    );
  };

  const displayAcceptedCNAEs = () => {
    return LIST_OF_ACCEPTED_CNAES.map(getAcceptedCNAEDisplay);
  };

  const OnMoreInfo = () => {
    setOpen(true);
  };

  return (
    <Form onChange={onChange} onSubmit={onSubmit} fields={fields}>
      <Form.Header
        title={invalidCnpj ? TEXT.INVALID_CNPJ_TITLE : TEXT.TITLE}
        subtitle={getSubtitleWhenCnpjIsInvalid()}
      />
      <Box component="span" pb={2}>
        {TEXT.MORE_ABOUT}
        <Typography
          variant="body1"
          component="a"
          href={TEXT.LINK_REGISTER_MEI}
          className={classes.link}
        >
          {TEXT.MORE_ABOUT_REGISTER_MEI_LINK}
        </Typography>
      </Box>

      {inputList.map(field => (
        <Form.Input key={field.name} field={field} />
      ))}

      <Box pb={1.5}>
        <Typography variant="body1">
          <Box component="span" fontWeight="fontWeightBold">
            CNAEs aceitos para entrega:
          </Box>
        </Typography>
      </Box>

      <Box pb={1.5}>{displayAcceptedCNAEs()}</Box>

      <Box component="a" className={classes.link} onClick={OnMoreInfo}>
        <Typography variant="body1">{TEXT.MORE_INFO}</Typography>
      </Box>
      <CNPJMoreInfo open={open} setOpen={setOpen} />

      <Form.Submit text={TEXT.FORM.SEND} disabled={disableSubmit} />
    </Form>
  );
};

CnpjFormTemplate.propTypes = {
  context: PropTypes.shape({
    fields: PropTypes.objectOf(PropTypes.shape({})),
    documents: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  disableSubmit: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
};

CnpjFormTemplate.defaultProps = {
  disableSubmit: false,
  onChange: () => {},
  onSubmit: () => {}
};

export default CnpjFormTemplate;
