import wretch from 'wretch';
import { getAuthorizationTokens } from '@loggi/authentication-lib';

import { getCredentials } from 'infra/storage/credential';
import { isEmbededDriverApp } from 'shared/utils/driver-app';
import {
  PARSE_ERROR,
  NETWORK_ERROR,
  translateStatusCode
} from '../request/http-message';
import { version } from '../../../package.json';

export async function getHeaders(headers) {
  const currentUser = await getCredentials();
  const { idToken } = await getAuthorizationTokens();
  let auth = '';
  if (isEmbededDriverApp() && window?.driverAppBridge?.getLWApiKey?.()) {
    auth = window?.driverAppBridge?.getLWApiKey?.();
  } else if (idToken) {
    auth = `Bearer ${idToken}`;
  }
  const observabilityId = currentUser.email ? currentUser.email : null;

  return {
    appVersion: version,
    observabilityId,
    service: 'driver-signup',
    Authorization: auth,
    ...headers
  };
}

export const headerMiddleware = next => async (url, opts) => {
  const headers = opts?.headers;

  const optsHeader = {
    ...opts,
    headers: await getHeaders(headers)
  };

  return next(url, optsHeader);
};

export default function restRequest() {
  const api = wretch()
    .url(`${process.env.REACT_APP_LOGGI_WEB_URL}/`)
    .content('application/json')
    .middlewares([headerMiddleware])
    .catcher('Error', err =>
      Promise.reject(
        translateStatusCode(err.response.status, err.message, err.json)
      )
    )
    .catcher('FetchError', () => Promise.reject(PARSE_ERROR))
    .catcher('__fromFetch', () => Promise.reject(NETWORK_ERROR));

  return api;
}
