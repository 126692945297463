import { getCredentials } from 'infra/storage/credential';

export const isEmbededDriverApp = () =>
  window.driverAppBridge?.isFromNewHome !== undefined &&
  window.driverAppBridge.isFromNewHome();

export const redirToDriversTarget = () => {
  const currentUser = getCredentials();
  const route = `${process.env.REACT_APP_DRIVERS_URL}?auth=ApiKey ${
    currentUser?.email
  }:${currentUser?.apiKey}&latitude=${
    currentUser.position?.latitude
  }&longitude=${currentUser.position?.longitude}`;
  window.location.href = route;

  return route;
};

export const isFsCognitoAuth = () =>
  window?.driverAppBridge?.isFsCognitoAuth?.();

export const isFsCognitoSmsAuth = () =>
  window?.driverAppBridge?.isFsCognitoSmsAuth?.();

export const isEnabledSms = () => {
  return isFsCognitoSmsAuth() && !isFsCognitoAuth();
};

export default isEmbededDriverApp;
