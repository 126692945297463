import { makeStyles } from '@material-ui/core';

export const TEXT = {
  TITLE: 'Crie sua conta',
  FULL_NAME: 'Nome completo',
  EMAIL: 'E-mail',
  PHONE: 'Telefone',
  CPF: 'CPF',
  CITY: 'Cidade que vai entregar',
  TRANSPORT_TYPE: 'Tipo de veículo',
  TERMS: 'Aceito que a Loggi use e guarde meus dados conforme os',
  LINK_TERMS: 'Termos de Uso',
  AND_PRIVACY_LINK: 'e o',
  LINK_PRIVACY: 'Aviso de Privacidade',
  MARKETING:
    'Também autorizo a Loggi e seus representantes a falarem comigo por e-mail, telefone, WhatsApp ou SMS nos contatos acima.',
  CREATE_ACCOUNT: 'Criar conta',
  FULL_TERMS: 'https://www.loggi.com/termos-condutor-autonomo/',
  PRIVACY_LINK: 'https://www.loggi.com/aviso-privacidade-clientes/'
};

export const useStyles = makeStyles(theme => ({
  contentTextMobile: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}));
