import get from 'lodash/get';
import graphqlRequest from '../graphql-request';

const requestGetDriverPicture = `
  {
    viewer {
      driver {
        documentProfilePicture {
          fileUrl
        }
      }
    }
  }
`;

export default function getDriverPicture() {
  return graphqlRequest({
    query: requestGetDriverPicture
  }).then(({ data, errors }) => {
    const { driver } = data.viewer;

    if (driver) {
      return {
        profilePicture: get(driver, 'documentProfilePicture.fileUrl', null)
      };
    }

    const errorResponse = { errors };
    return Promise.reject(errorResponse);
  });
}
