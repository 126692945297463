import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, Typography, Hidden } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import { ReactComponent as LoggiIcon } from './svg/loggi.svg';
import { ReactComponent as LoggiWhiteIcon } from './svg/loggi-white.svg';
import { TEXT, useStyles } from './constants';

export default function SignupLayoutTemplate({ page, onBack, loggiIcon }) {
  const classes = useStyles();

  const onHelpMe = event => {
    event.preventDefault();
    window.open(TEXT.HELP_ME_LINK);
  };

  return (
    <Grid container className={classes.header}>
      <Hidden smDown>
        <Grid
          item
          sm={12}
          md={6}
          className={`${classes.headerDesktop} ${classes.contentGrid}`}
        >
          <Box
            pt={11}
            px={3.5}
            pb={2.5}
            className={`${classes.whiteText} ${classes.contentText}`}
          >
            <LoggiWhiteIcon height="60px" />
            <Box pt={13}>
              <Typography variant="h2">
                <Box component="span" fontWeight="fontWeightBold">
                  {TEXT.TITLE}
                </Box>
              </Typography>
            </Box>
            <Box pt={2.5}>
              <Typography variant="h6">{TEXT.SUBTITLE}</Typography>
            </Box>
          </Box>
        </Grid>
      </Hidden>
      <Grid item sm={12} md={6} className={classes.contentGrid}>
        <Box pt={5} px={3.5} pb={2.5} className={classes.containerPage}>
          <Grid container justify="space-between">
            {onBack && (
              <ArrowBack
                className={classes.blueIcon}
                data-testid="onBackIconButton"
                onClick={onBack}
              />
            )}

            {loggiIcon ? <LoggiIcon height="34px" /> : <Box />}

            <Box component="a" className={classes.link} onClick={onHelpMe}>
              {TEXT.HELP_ME}
            </Box>
          </Grid>

          <Box className={classes.contentPage}>{page}</Box>
        </Box>
      </Grid>
    </Grid>
  );
}

SignupLayoutTemplate.propTypes = {
  page: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  onBack: PropTypes.func,
  loggiIcon: PropTypes.bool
};

SignupLayoutTemplate.defaultProps = {
  onBack: null,
  loggiIcon: false
};
