import {
  AmplifyAuthProvider,
  AmplifyConfigure
} from '@loggi/authentication-lib';
import FirebaseConfigProvider from '@loggi/firebase-feature-switches';

import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';

import firebaseInit from 'operations/config/firebase';
import registerServiceWorker from './serviceWorkerRegistration';
import CredentialsProvider from './credentials-provider';
import App from './App';
import config from './aws-exports';

import 'operations/config/sentry';

firebaseInit();
AmplifyConfigure(config);

ReactDOM.render(
  <FirebaseConfigProvider>
    <AmplifyAuthProvider>
      <CredentialsProvider>
        <SnackbarProvider
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'top'
          }}
          autoHideDuration={6000}
          maxSnack={3}
        >
          <App />
        </SnackbarProvider>
      </CredentialsProvider>
    </AmplifyAuthProvider>
  </FirebaseConfigProvider>,
  document.getElementById('root')
);

registerServiceWorker();
