import React from 'react';
import PropTypes from 'prop-types';
import { Interpreter } from 'xstate';
import { useService } from '@xstate/react';

import LoadingTemplate from 'view/templates/loading';
import LoadingErrorTemplate from 'view/templates/loading-error';

export default function InitializationTemplate({
  context: {
    services: { initializationMachine }
  }
}) {
  const [current, send] = useService(initializationMachine);
  const error = current.matches('error');
  const handleRetry = () => send('RETRY');

  if (error) {
    return (
      <LoadingErrorTemplate
        errors={current.context.errors}
        onRetry={handleRetry}
      />
    );
  }

  return <LoadingTemplate />;
}

InitializationTemplate.propTypes = {
  context: PropTypes.shape({
    services: PropTypes.shape({
      initializationMachine: PropTypes.instanceOf(Interpreter).isRequired
    })
  }).isRequired
};
