import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Button } from '@material-ui/core';

import BlueCheckWithText from 'shared/utils/blue-check-with-text';
import { TEXT, useStyles } from './constants';
import { TEXTS as DOCUMENT_HUB_TEXT } from '../document-hub/constants';

export default function FinishCreateAccountTemplate({
  isDesktop,
  onContinue,
  driver
}) {
  const classes = useStyles();

  const contentTextClass = isDesktop ? '' : classes.contentTextMobile;

  const title =
    driver && driver.firstName
      ? TEXT.TITLE.replace('<<NOME>>', driver.firstName)
      : TEXT.TITLE_EMPTY_NAME;

  const requirements = [
    TEXT.MEI_REQUIREMENT,
    TEXT.CNH_REQUIREMENT,
    TEXT.BANK_REQUIREMENT
  ];

  return (
    <Box className={contentTextClass}>
      <Box flexGrow={1} alignItems="flex-start">
        <Box width="100%">
          <Box pt={5}>
            <Typography variant="h5">
              <Box component="span" fontWeight="fontWeightBold">
                {title}
              </Box>
            </Typography>
          </Box>

          <Box>
            <Typography variant="h5">{TEXT.SUBTITLE}</Typography>
          </Box>

          <Box pt={4}>
            <Typography variant="body1">{TEXT.START_SEND_DOCUMENTS}</Typography>
          </Box>

          <Box pt={3.5}>
            <BlueCheckWithText texts={requirements} pb={2.5} />
          </Box>
        </Box>
      </Box>
      <Box mt={isDesktop ? 5.5 : 3.5} pb={2.5}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={onContinue}
        >
          {TEXT.CONTINUES}
        </Button>
      </Box>
      <Box pt={1} pb={2.5}>
        <Button variant="outlined" color="primary" size="large" fullWidth>
          {DOCUMENT_HUB_TEXT.LOGOUT}
        </Button>
      </Box>
    </Box>
  );
}

FinishCreateAccountTemplate.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  onContinue: PropTypes.func.isRequired,
  driver: PropTypes.shape({
    firstName: PropTypes.string
  }).isRequired
};
