export const PERMISSION_DENIED_MESSAGE =
  'Você não tem permissão para acessar este serviço, verifique com o administrador';
export const NETWORK_ERROR_MESSAGE =
  'Erro ao acessar o servidor. Tente de novo.';
export const NOT_FOUND_MESSAGE = 'Endereço não encontrado';
export const DEFAULT_MESSAGE = 'Falha no sistema. Tente de novo.';

const PARSE_ERROR = {
  errors: [
    {
      message: DEFAULT_MESSAGE
    }
  ],
  code: 0
};

const NETWORK_ERROR = {
  errors: [
    {
      message: NETWORK_ERROR_MESSAGE
    }
  ],
  code: 0
};

const RESPONSE_MESSAGES_BY_CODE = {
  401: DEFAULT_MESSAGE,
  403: PERMISSION_DENIED_MESSAGE,
  404: NOT_FOUND_MESSAGE,
  500: DEFAULT_MESSAGE,
  502: DEFAULT_MESSAGE,
  504: DEFAULT_MESSAGE
};

function translateStatusCode(status, message = '', content = null) {
  const isArray = obj => Array.isArray(obj) || obj instanceof Array;

  if (RESPONSE_MESSAGES_BY_CODE[status]) {
    const messageError = RESPONSE_MESSAGES_BY_CODE[status];

    return {
      errors: [{ message: messageError }],
      code: status
    };
  }

  if (content && content.errors && isArray(content.errors)) {
    const errors = content.errors
      .map(error => ({ message: error.message }))
      .filter(error => error && error.message);

    if (errors) {
      return { errors, code: status };
    }
  }

  if (message) {
    if (message instanceof String || typeof message === 'string') {
      const REMOVE_QUOTES = new RegExp(/('|")/g);
      const messageError = message.replace(REMOVE_QUOTES, '');
      return { errors: [{ message: messageError }], code: status };
    }

    return { errors: message, code: status };
  }

  return { errors: [], code: 0 };
}

export { PARSE_ERROR, NETWORK_ERROR, translateStatusCode };
