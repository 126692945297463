import { Machine, sendParent } from 'xstate';

export const STATES = {
  idle: 'idle',
  finished: 'finished'
};

export const actions = {
  sendInitialize: sendParent(() => {
    return {
      type: 'SET_LOADING'
    };
  }),
  sendAuthorized: sendParent(() => ({
    type: 'AUTHORIZED'
  }))
};

const finishCreateAccountMachine = Machine(
  {
    id: 'finishCreateAccountMachine',
    initial: STATES.idle,
    states: {
      [STATES.idle]: {
        on: {
          AUTHORIZED: {
            actions: ['sendInitialize', 'sendAuthorized'],
            target: STATES.finished
          }
        }
      },
      [STATES.finished]: {
        type: 'final'
      }
    }
  },
  {
    actions
  }
);

export default finishCreateAccountMachine;
