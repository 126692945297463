const OLD_PATTERN_BR = new RegExp(/^([0-9]{0,2})?([0-9]{0,4})?([0-9]{0,4})?$/);
const NEW_PATTERN_BR = new RegExp(/^([0-9]{0,2})?([0-9]{0,5})?([0-9]{0,4})?$/);

export function mobilePhoneFormatter(value) {
  if (!value) {
    return '';
  }

  const phone = value.replace(/[^0-9]/g, '').slice(0, 11);

  if (!phone) {
    return phone;
  }

  const pattern = phone.length === 11 ? NEW_PATTERN_BR : OLD_PATTERN_BR;
  let mask = '($1) $2-$3';
  if (phone.length <= 2) {
    mask = '($1';
  } else if (phone.length <= 6) {
    mask = '($1) $2';
  }

  return phone.replace(pattern, mask);
}

export function removeMobilePhoneFormatter(value) {
  if (!value) {
    return '';
  }

  return value.replace(/[^0-9]/g, '').slice(0, 11);
}
