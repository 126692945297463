import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Form from 'view/templates/base-form';
import {
  TEXTS,
  useStyles,
  ACCOUNT_TYPE_OPTIONS,
  OWNER_TYPES_OPTIONS
} from './constants';

const BankAccountFormTemplate = ({
  onChange,
  onSubmit,
  context,
  isMeiApproved,
  disableSubmit
}) => {
  const { fields, bankAccounts } = context;

  const bankOptions = bankAccounts.map(bankAccount => ({
    label: bankAccount.name,
    value: bankAccount.code
  }));

  const inputList = [
    {
      name: 'bank',
      placeholder: TEXTS.FORM.BANK,
      options: bankOptions,
      isSelect: true
    },
    {
      name: 'agency',
      placeholder: TEXTS.FORM.AGENCY,
      inputProps: { maxLength: 6 },
      InputProps: {
        endAdornment: (
          <Tooltip title={TEXTS.TOOLTIP}>
            <span>
              <HelpIcon fontSize="small" />
            </span>
          </Tooltip>
        )
      }
    },
    {
      name: 'account',
      placeholder: TEXTS.FORM.ACCOUNT,
      inputProps: { maxLength: 20 }
    },
    {
      name: 'user_type',
      placeholder: TEXTS.FORM.OWNER,
      options: OWNER_TYPES_OPTIONS(isMeiApproved),
      isSelect: true
    },
    {
      name: 'account_type',
      placeholder: TEXTS.FORM.TYPE,
      options: ACCOUNT_TYPE_OPTIONS,
      isSelect: true
    }
  ];

  const styles = useStyles();

  return (
    <Form onChange={onChange} onSubmit={onSubmit} fields={fields}>
      <Form.Header title={TEXTS.TITLE} subtitle={TEXTS.SUBTITLE} />

      {inputList.map(field => (
        <Form.Input key={field.name} field={field} />
      ))}

      <Box py={2}>
        <Typography variant="subtitle2">
          <Box pb={1.5} fontWeight="fontWeightBold">
            {TEXTS.INFO_TITLE}
          </Box>
        </Typography>

        <List className={styles.list}>
          {TEXTS.INFO_ITEMS.map(item => (
            <ListItem key={item} className={styles.listItem}>
              <ListItemIcon className={styles.listIcon}>
                <CloseRoundedIcon color="error" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body1">{item}</Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Box>

      <Form.Submit text="Enviar" disabled={disableSubmit} />
    </Form>
  );
};

BankAccountFormTemplate.propTypes = {
  context: PropTypes.shape({
    fields: PropTypes.objectOf(PropTypes.shape({})),
    bankAccounts: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  isMeiApproved: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
};

BankAccountFormTemplate.defaultProps = {
  isMeiApproved: false,
  disableSubmit: false,
  onChange: () => {},
  onSubmit: () => {}
};

export default BankAccountFormTemplate;
