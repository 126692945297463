import { createContext, useContext } from 'react';

const FormContext = createContext({});

export const useForm = () => {
  const context = useContext(FormContext);
  return context;
};

export const { Provider: FormProvider, Consumer: InputConsumer } = FormContext;

export default FormContext;
