import React from 'react';
import PropTypes from 'prop-types';
import { Interpreter } from 'xstate';
import { useService } from '@xstate/react';

import { FORM_STATE, FORM_ACTIONS } from 'machines/utils/form';
import PAGES, { paths } from 'routes/pages';
import EmailVerificationTemplate from 'view/templates/email-verification';
import LoadingTemplate from 'view/templates/loading';
import SignupLayoutTemplate from 'view/templates/signup-layout';

export default function EmailVerificationPage({
  isDesktop,
  history,
  context: {
    services: { EmailVerificationMachine }
  }
}) {
  const [current, send] = useService(EmailVerificationMachine);

  const isLogin = history.location.state?.from === paths.documents.hub;

  const onCheck = () => {
    if (isLogin) {
      send('SET_LOGIN');
    }
    send(FORM_ACTIONS.submit);
  };

  const onRetry = () => {
    send(FORM_ACTIONS.retry);
  };

  if (current.matches(FORM_STATE.success)) {
    history.push(
      isLogin ? PAGES.DOCUMENT_HUB.path : PAGES.FINISH_CREATE_ACCOUNT.path,
      { from: PAGES.EMAIL_VERIFICATION.path }
    );
    return <></>;
  }

  const component =
    current.matches(FORM_STATE.loading) ||
    current.matches(FORM_STATE.submitting) ? (
      <LoadingTemplate />
    ) : (
      <EmailVerificationTemplate
        isDesktop={isDesktop}
        onCheck={onCheck}
        onRetry={onRetry}
        email={current.context.driver.email}
      />
    );

  return <SignupLayoutTemplate page={component} />;
}

EmailVerificationPage.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      state: PropTypes.shape({
        from: PropTypes.string.isRequired
      })
    })
  }).isRequired,
  context: PropTypes.shape({
    services: PropTypes.shape({
      EmailVerificationMachine: PropTypes.instanceOf(Interpreter)
    })
  }).isRequired
};
