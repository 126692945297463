import graphqlRequest from 'infra/graphql-request/';

const driverNodeQuery = `
  query DriverNodeQuery {
    driver {
        emailIsVerified
    }
  }
`;

export const error = 'E-mail not verified';

export default function checkEmailVerificationService() {
  return graphqlRequest({
    query: driverNodeQuery,
    variables: {}
  }).then(({ data }) => {
    const { driver } = data || {};
    if (driver) {
      const { emailIsVerified } = driver;
      if (emailIsVerified) {
        return emailIsVerified;
      }
    }

    return Promise.reject(error);
  });
}
