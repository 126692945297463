import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useForm } from '../context';

const Input = ({ field, render }) => {
  const context = useForm();

  if (render) {
    return render(context);
  }

  const { fieldError, handleBlur, handleChange, fields } = context;

  const value = fields && fields[field.name] && fields[field.name].value;
  const commonTextFieldProps = {
    value,
    id: field.name,
    name: field.name,
    variant: 'outlined',
    fullWidth: true,
    onChange: handleChange,
    onBlur: handleBlur,
    helperText: fieldError(field.name),
    error: !!fieldError(field.name),
    type: field.type,
    inputProps: { ...field.inputProps, 'data-testid': field.name },
    InputProps: field.InputProps,
    disabled: field.disabled
  };
  const commonCheckboxFieldProps = {
    ...(value ? { checked: Boolean(value) } : {}),
    id: field.name,
    name: field.name,
    variant: 'outlined',
    onChange: handleChange,
    onBlur: handleBlur,
    inputProps: { 'data-testid': field.name }
  };

  if (field.isSelect) {
    return (
      <Box pb={2.5} key={field.name}>
        <TextField
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...commonTextFieldProps}
          select
          SelectProps={{
            displayEmpty: true,
            native: true
          }}
          label={field.placeholder}
        >
          <option value="">&nbsp;</option>
          {field.options &&
            field.options.map(option => (
              <option
                id={`opt-${option.value}`}
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </option>
            ))}
        </TextField>
      </Box>
    );
  }
  if (field.isCheckbox) {
    return (
      <Box pb={2.5} key={field.name}>
        <FormControl required error={!!fieldError(field.name)}>
          <FormControlLabel
            label={field.label}
            control={
              <Checkbox
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...commonCheckboxFieldProps}
              />
            }
          />
          <FormHelperText>{fieldError(field.name)}</FormHelperText>
        </FormControl>
      </Box>
    );
  }
  return (
    <Box pb={2.5} key={field.name}>
      <TextField
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...commonTextFieldProps}
        placeholder={field.placeholder}
        label={field.label}
      />
    </Box>
  );
};

Input.propTypes = {
  render: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    isSelect: PropTypes.bool,
    isCheckbox: PropTypes.bool,
    inputProps: PropTypes.shape({}),
    InputProps: PropTypes.shape({}),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        disabled: PropTypes.bool
      })
    ),
    label: PropTypes.node,
    disabled: PropTypes.bool
  })
};

Input.defaultProps = {
  field: {},
  render: null
};

export default Input;
