import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Form from 'view/templates/base-form';
import Picture from '../components/picture';
import { TEXTS } from '../constants';

const ErrorUploadForm = ({ context, onSubmit }) => {
  const { driver } = context;
  const { TITLE, SUBTITLE } = TEXTS.ERROR;
  return (
    <Form>
      <Form.Header title={TITLE} subtitle={SUBTITLE} />

      <Box
        data-testid="error-form"
        pb={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Picture picture={driver.profilePicture} />
      </Box>

      <Form.Submit text="Enviar outra" onClick={onSubmit} />
    </Form>
  );
};

ErrorUploadForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  context: PropTypes.shape({
    driver: PropTypes.shape({
      profilePicture: PropTypes.string
    })
  }).isRequired
};

export default ErrorUploadForm;
