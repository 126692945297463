import { Machine, assign, sendParent } from 'xstate';

import checkEmailVerificationService from 'infra/services/check-email-verification';
import requestEmailVerificationService from 'infra/services/request-email-verification';
import {
  FORM_STATE,
  FORM_ACTIONS,
  sendErrorNotification,
  sendSuccessNotification
} from './utils/form';

const FORM_NOTIFICATIONS = {
  resend_email: 'Beleza! Reenviamos o e-mail para você.',
  email_not_validated:
    'Eita. Seu e-mail ainda não foi verificado. Você precisa confirmar para poder continuar.'
};

export const actions = {
  mergeErrors: assign({
    errors: (_, event) => event.data.errors
  }),
  sendErrorNotification,
  sendEmailNotValidatedNotification: sendErrorNotification(
    FORM_NOTIFICATIONS.email_not_validated
  ),
  sendResendEmailNotification: sendSuccessNotification(
    FORM_NOTIFICATIONS.resend_email
  ),
  setDriverEmailIsVerifiedParent: sendParent(() => ({
    type: 'SETEMAILISVERIFIED'
  })),
  sendAuthorized: sendParent(context => ({
    type: context.isLogin ? 'AUTHORIZED' : 'UNAUTHORIZED'
  })),
  setLogin: assign({
    isLogin: true
  })
};

const resendEmailVerification = context =>
  requestEmailVerificationService({
    email: context.driver.email
  });

export const services = {
  checkEmailVerificationService,
  resendEmailVerification
};

const EmailVerificationMachine = Machine(
  {
    id: 'EmailVerificationMachine',
    context: { isLogin: false },
    initial: FORM_STATE.submitting,
    states: {
      [FORM_STATE.idle]: {
        on: {
          [FORM_ACTIONS.submit]: {
            target: FORM_STATE.submitting
          },
          [FORM_ACTIONS.retry]: {
            target: FORM_STATE.loading
          },
          SET_LOGIN: {
            actions: 'setLogin'
          }
        }
      },
      [FORM_STATE.submitting]: {
        invoke: {
          id: 'checkEmailVerificationService',
          src: 'checkEmailVerificationService',
          onDone: {
            target: FORM_STATE.success,
            actions: ['setDriverEmailIsVerifiedParent', 'sendAuthorized']
          },
          onError: {
            target: FORM_STATE.idle,
            actions: ['sendEmailNotValidatedNotification']
          }
        }
      },
      [FORM_STATE.loading]: {
        invoke: {
          id: 'resendEmailVerification',
          src: 'resendEmailVerification',
          onDone: {
            target: FORM_STATE.idle,
            actions: ['sendResendEmailNotification']
          },
          onError: {
            target: FORM_STATE.idle,
            actions: ['mergeErrors', 'sendErrorNotification']
          }
        }
      },
      [FORM_STATE.success]: {
        type: 'final'
      }
    }
  },
  {
    actions,
    services
  }
);

export default EmailVerificationMachine;
