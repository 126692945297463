import graphqlRequest from 'infra/graphql-request';

const requestEmailVerification = `
mutation RequestEmailVerification($email: String!) {
    requestEmailVerification(input: {
        email: $email
    }) {
        isSuccessfully: success
        errors {
            message
        }
   }
 }
`;

export default function requestEmailVerificationService(variables) {
  return graphqlRequest({
    query: requestEmailVerification,
    variables
  }).then(({ data }) => {
    const response = data.requestEmailVerification;

    if (response && response.isSuccessfully) {
      return response;
    }

    const errorResponse = { errors: response.errors };
    return Promise.reject(errorResponse);
  });
}
