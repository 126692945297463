import graphqlRequest from 'infra/graphql-request/';

const allOperatingCitiesQuery = `
  query AllOperatingCitiesQuery {
    allCities(hasLocalDeliveries: true) {
      edges {
        city: node {
          id
          name
          active
          state
        	pk
          slug
        }
      }
    }
  }
`;

export default function allOperatingCities() {
  return graphqlRequest({
    query: allOperatingCitiesQuery,
    variables: {}
  }).then(({ data }) => data.allCities.edges.map(item => item.city));
}
