import restRequest from 'infra/rest-request';

export const USER_RESTORE_URL = 'api/v2/drivers/create-driver-profile-for-user';
export const DRIVER_ERROR_TRANSLATE_MAP = {
  'CPF already in use': 'Opa, esse CPF já está cadastrado.',
  'Mobile already in use': 'Opa, esse telefone já está cadastrado.',
  'Email already in use': 'Opa, esse e-mail já está cadastrado.'
};

export const handleError = ({ message }) => {
  const error = message && message.replace(/"g/, '');
  return Promise.reject(DRIVER_ERROR_TRANSLATE_MAP[error] || error);
};

export const getUserRestoreInfo = ({ searchParams }) => {
  const token = searchParams.get('tk');
  return restRequest()
    .url(USER_RESTORE_URL)
    .auth(`Bearer ${token}`)
    .get()
    .json();
};

export const updateDriverInfo = ({ searchParams }, data) => {
  const token = searchParams.get('tk');
  return restRequest()
    .url(USER_RESTORE_URL)
    .auth(`Bearer ${token}`)
    .post(data)
    .error(412, handleError)
    .text();
};

const UserRestore = {
  getUserRestoreInfo,
  updateDriverInfo
};

export default UserRestore;
