import { makeStyles } from '@material-ui/core';

export const TEXT = {
  TITLE: 'Verifique seu e-mail',
  SUBTITLE:
    'Enviamos um e-mail de verificação para <<EMAIL>>. Acesse seu e-mail e clique em Verificar.',
  VERIFY: 'Já validei o e-mail',
  RESEND: 'Não recebi o e-mail'
};

export const useStyles = makeStyles(theme => ({
  contentText: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}));
